// src/components/FeedbackModal.js
import React from 'react';
import './FeedbackModal.css';

const FeedbackModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>We Value Your Feedback</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" placeholder="Your name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Your email" required />
          </div>
          <div className="form-group">
            <label htmlFor="feedback">Feedback</label>
            <textarea id="feedback" placeholder="Your feedback" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default FeedbackModal;
