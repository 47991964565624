import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>Last updated: September 2, 2024</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to Tech101. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at support@tech101.ae.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us when you register on the platform, express an interest in obtaining information about us or our products and services, when you participate in activities on the platform, or otherwise when you contact us.
        </p>

        <h3>Personal Information Provided by You</h3>
        <p>
          The personal information that we collect depends on the context of your interactions with us and the platform, the choices you make, and the products and features you use. The personal information we collect may include the following:
        </p>
        <ul>
          <li>Names</li>
          <li>Email addresses</li>
          <li>Contact information</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>
          We use personal information collected via our platform for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
        </p>
        <ul>
          <li>To facilitate account creation and login process.</li>
          <li>To send administrative information to you.</li>
          <li>To fulfill and manage your orders.</li>
          <li>To post testimonials.</li>
          <li>To request feedback.</li>
          <li>To protect our services.</li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>
          We may process or share your data that we hold based on the following legal basis:
        </p>
        <ul>
          <li>Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
          <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
          <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
          <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process.</li>
        </ul>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          We may use cookies and similar tracking technologies to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
        </p>

        <h2>6. Data Retention</h2>
        <p>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law.
        </p>

        <h2>7. Your Privacy Rights</h2>
        <p>
          In some regions (like the European Economic Area), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have questions or comments about this notice, you may email us at support@tech101.ae or by post to:
        </p>
        <address>
          Tech101 <br />
          Sharjah Research Technology & Innovation Park <br />
          University City <br />
          Sharjah, U.A.E
        </address>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
