import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import ProgramsSection from './components/ProgramsSection';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import About from './pages/About';
import Corporate from './pages/Corporate';
import Feedback from './pages/Feedback';
import Contact from './pages/Contact';
import SystemDesignInFigma from './pages/SystemDesignInFigma';
import CartPage from './pages/CartPage';
import { CartProvider } from './CartContext';
import CheckoutPage from './pages/CheckoutPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TestimonialsSection from './components/TestimonialsSection';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Legal from './pages/Legal';
import SiteMap from './pages/SiteMap';
import BasicsOfUXResearch from './pages/BasicsOfUXResearch'; 
import AdvancedJavascript from './pages/AdvancedJavascript';
import IntroToCybersecurity from './pages/IntroToCybersecurity';  
import IntroToPythonProgramming from './pages/IntroToPythonProgramming';
import IntroToPromptEngineering from './pages/IntroToPromptEngineering';


const stripePromise = loadStripe('pk_test_51Ptt33CkzNEXLPpL7fDjo22p757YnYFUn3HVFdp5Z2e7uwlBX2OppG1MfH4bPh9vjQVr7LXK2oQkKfMYQFJZhLtm00wY0H9VKd');

function App() {
  const location = useLocation();
  const hideNavBar = location.pathname === '/login' || location.pathname === '/signup';

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <CartProvider>
      {!hideNavBar && <NavBar />}
      <Routes>
        <Route path="/" element={<>
                                  <HeroSection />
                                  <ProgramsSection />
                                  <TestimonialsSection />  
                                  <Footer /> {/* Add Footer here */}
                                </>} 
        />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/about" element={<About />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/courses/system-design-in-figma" element={<SystemDesignInFigma />} />
        <Route path="/courses/ux-research-basics" element={<BasicsOfUXResearch />} />
        <Route path="/courses/advanced-javascript" element={<AdvancedJavascript />} />
        <Route path="/courses/intro-to-cybersecurity" element={<IntroToCybersecurity />} />
        <Route path="/courses/intro-python-programming" element={<IntroToPythonProgramming />} />
        <Route path="/courses/prompt-engineering" element={<IntroToPromptEngineering />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/sitemap" element={<SiteMap />} />
        <Route 
          path="/checkout" 
          element={
            <Elements stripe={stripePromise}>
              <CheckoutPage />
            </Elements>
          } 
        />
      </Routes>
    </CartProvider>
  );
}

export default App;
