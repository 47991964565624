import React, { useState, useContext } from 'react';
import './IntroToPromptEngineering.css';
import { CartContext } from '../CartContext'; // Import the CartContext

const IntroToPromptEngineering = () => {
    const [activeTab, setActiveTab] = useState('Overview');
    const [showMoreMastery, setShowMoreMastery] = useState(false);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const { addToCart } = useContext(CartContext); // Get the addToCart function from CartContext

    // Product object with image property
    const product = {
        id: 'prompt-engineering',
        name: 'Intro to Prompt Engineering',
        price: 4000,
        originalPrice: 6000,
        image: '/images/prompt-engineering-icon.png' // Path to the product image
    };

    const toggleWeek = (week) => {
        setExpandedWeeks(prevState => ({
            ...prevState,
            [week]: !prevState[week]
        }));
    };

    const renderTabContent = () => {
        if (activeTab === 'Overview') {
            return (
                <div className="course-description">
                    <h2>Description</h2>
                    <p>Welcome to Intro to Prompt Engineering. This course introduces you to the world of AI-driven prompt engineering, teaching you how to craft effective prompts that maximize results from AI models like GPT. By the end of this course, you'll have the foundational skills to enhance your AI outputs in various real-world applications.</p>
                    <h3>What You Will Master</h3>
                    <ul className={`mastery-list ${showMoreMastery ? 'expanded' : ''}`}>
                        <li>Understanding AI Prompt Structures: Learn the components of a well-designed AI prompt.</li>
                        <li>Writing Effective Prompts: Create prompts that generate the desired output from AI models.</li>
                        <li>Optimizing Prompts for Specific Use Cases: Tailor prompts for writing, coding, and creative tasks.</li>
                        {showMoreMastery && (
                            <>
                                <li>Advanced Prompting Techniques: Delve deeper into multi-step and iterative prompt writing.</li>
                                <li>Real-World Applications: Implement prompts in business, education, and creative projects.</li>
                                <li>Ethical Considerations: Understand the ethical concerns surrounding prompt engineering.</li>
                            </>
                        )}
                    </ul>
                    <button className="read-more-btn" onClick={() => setShowMoreMastery(!showMoreMastery)}>
                        {showMoreMastery ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        } else if (activeTab === 'Syllabus') {
            return (
                <div className="syllabus-grid">
                    <div className="syllabus-column">
                        <h3>Week 1: Introduction to AI & Prompt Engineering</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(1)}>Understanding AI Models</strong>
                                {expandedWeeks[1] && (
                                    <ul>
                                        <li>Introduction to AI: Understanding AI models and their real-world applications.</li>
                                        <li>What is Prompt Engineering: Learn how prompts interact with AI systems like GPT.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(2)}>Fundamentals of Prompt Design</strong>
                                {expandedWeeks[2] && (
                                    <ul>
                                        <li>What Makes a Good Prompt: Key components of an effective prompt.</li>
                                        <li>Prompt Design Patterns: Explore common patterns for writing prompts.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Week 2: Crafting Prompts for Different Tasks</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(3)}>Writing Prompts for Writing Tasks</strong>
                                {expandedWeeks[3] && (
                                    <ul>
                                        <li>Using Prompts for Content Creation: Generate stories, blogs, and more.</li>
                                        <li>Customizing Prompts for Tone and Style: Tailoring outputs to specific audiences.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(4)}>Coding Prompts and Debugging</strong>
                                {expandedWeeks[4] && (
                                    <ul>
                                        <li>Using AI to Write Code: How prompts can assist with coding tasks.</li>
                                        <li>Debugging with Prompts: Learn to use prompts to solve coding issues.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Week 3: Advanced Techniques for Effective Prompts</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(5)}>Multi-step Prompts</strong>
                                {expandedWeeks[5] && (
                                    <ul>
                                        <li>Breaking Down Complex Tasks: How to split tasks into smaller prompts.</li>
                                        <li>Iterative Prompting: Refining AI responses through multiple steps.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(6)}>Working with Conditional Prompts</strong>
                                {expandedWeeks[6] && (
                                    <ul>
                                        <li>Conditional Logic in Prompts: Learn how to structure prompts for conditional outputs.</li>
                                        <li>Handling Different Outcomes: Customizing responses based on conditions.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Week 4: Applying Prompt Engineering in Real Life</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(7)}>Business Use Cases</strong>
                                {expandedWeeks[7] && (
                                    <ul>
                                        <li>AI for Business Writing: Crafting business reports, emails, and proposals.</li>
                                        <li>Automation with Prompts: Using prompts to automate repetitive business tasks.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(8)}>Creative Use Cases</strong>
                                {expandedWeeks[8] && (
                                    <ul>
                                        <li>Generating Art with Prompts: How AI can assist in creative design.</li>
                                        <li>Creative Writing and Storytelling: Using AI to enhance creativity.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Instructor') {
            return (
                <div className="instructor-section">
                    <div className="instructor-header">
                        <h2>About Me, <span>Mustafa Khanani</span></h2>
                        <p>I'm a tech expert specializing in AI and prompt engineering. My goal is to empower others by providing actionable insights into using AI tools efficiently.</p>
                    </div>
                    <div className="instructor-profile">
                        <div className="instructor-photo">
                            <img src="/images/instructor-photo.png" alt="Mustafa Khanani" />
                        </div>
                        <div className="instructor-info">
                            <h3>Your guide to mastering AI prompts</h3>
                            <p>I have been actively involved in AI development, and I am excited to share my experience with you in this emerging field.</p>
                        </div>
                    </div>
                </div>
            );
        } else if (activeTab === 'Reviews') {
            return (
                <div>
                    {/* Reviews content goes here */}
                </div>
            );
        }
    };

    return (
        <div className="course-page">
            <div className="main-content">
                <div className="course-header">
                    <div className="title-and-rating">
                        <div className="course-title-badge">
                            <h1>Intro to Prompt Engineering</h1>
                        </div>
                        <div className="course-rating">
                            <span>4.8</span> <span>(10 reviews)</span>
                        </div>
                    </div>
                    <div className="course-image">
                        <img src="/images/prompt-engineering-course.png" alt="Intro to Prompt Engineering" />
                    </div>
                    <div className="course-tabs">
                        <button
                            className={`tab ${activeTab === 'Overview' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Overview')}
                        >
                            Overview
                        </button>
                        <button
                            className={`tab ${activeTab === 'Syllabus' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Syllabus')}
                        >
                            Syllabus
                        </button>
                        <button
                            className={`tab ${activeTab === 'Instructor' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Instructor')}
                        >
                            Instructor
                        </button>
                        <button
                            className={`tab ${activeTab === 'Reviews' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Reviews')}
                        >
                            Reviews (10+)
                        </button>
                    </div>
                </div>

                {renderTabContent()}
            </div>

            <div className="course-sidebar">
                <h2>Course Content</h2>
                <div className="course-info">
                    <p><img src="/images/video-icon.png" alt="Video Content" /> 8 hours+ on-demand video content</p>
                    <p><img src="/images/keyboard-icon.png" alt="Keyboard Exercises" /> 8 hands-on exercises</p>
                    <p><img src="/images/certificate-icon.png" alt="Certificate" /> Certificate of completion</p>
                    <p><img src="/images/level-icon.png" alt="Level" /> Beginner</p>
                </div>

                <div className="course-price">
                    <p className="original-price">AED 6000</p>
                    <p className="discounted-price">AED 4000</p>
                </div>
                <button className="buy-now-btn">Buy now</button>
                <button className="add-to-cart-btn" onClick={() => addToCart(product)}>Add to Cart</button>

                <div className="payment-options">
                    <p>Secure payment with:</p>
                    <div className="payment-logos">
                        <img src="/images/paypal-logo.png" alt="PayPal" />
                        <img src="/images/stripe-logo.png" alt="Stripe" />
                        <img src="/images/visa-logo.png" alt="Visa" />
                        <img src="/images/mastercard-logo.png" alt="Mastercard" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroToPromptEngineering;
