import React, { useState, useContext } from 'react';
import './IntroToCybersecurity.css';
import { CartContext } from '../CartContext'; // Import the CartContext

const IntroToCybersecurity = () => {
    const [activeTab, setActiveTab] = useState('Overview');
    const [showMoreMastery, setShowMoreMastery] = useState(false);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const { addToCart } = useContext(CartContext); // Get the addToCart function from CartContext

    // Product object with image property
    const product = {
        id: 'intro-to-cybersecurity',
        name: 'Intro to Cybersecurity',
        price: 4000,
        originalPrice: 5000,
        image: '/images/cybersecurity-icon.png' // Path to the product image
    };

    const toggleWeek = (week) => {
        setExpandedWeeks(prevState => ({
            ...prevState,
            [week]: !prevState[week]
        }));
    };

    const renderTabContent = () => {
        if (activeTab === 'Overview') {
            return (
                <div className="course-description">
                    <h2>Description</h2>
                    <p>This course provides a comprehensive introduction to the field of cybersecurity. You'll learn the fundamentals of securing information systems, understanding various cyber threats, and implementing security measures to protect digital assets. Whether you're a beginner or looking to enhance your skills, this course will equip you with the knowledge needed to safeguard your digital presence.</p>
                    <h3>What You Will Master</h3>
                    <ul className={`mastery-list ${showMoreMastery ? 'expanded' : ''}`}>
                        <li>Understanding Cyber Threats: Gain insights into different types of cyber threats, including malware, phishing, and social engineering.</li>
                        <li>Implementing Security Measures: Learn how to implement basic security measures such as firewalls, encryption, and multi-factor authentication.</li>
                        <li>Securing Networks and Systems: Develop skills in securing networks and systems to prevent unauthorized access and data breaches.</li>
                        <li>Responding to Cyber Incidents: Master the basics of incident response, including identifying, analyzing, and mitigating cyber attacks.</li>
                        {showMoreMastery && (
                            <>
                                <li>Protecting Personal Data: Understand the importance of data protection and how to secure personal information online.</li>
                                <li>Building Secure Applications: Learn best practices for building secure applications and preventing common vulnerabilities.</li>
                                <li>Ethical Hacking and Penetration Testing: Get introduced to ethical hacking and penetration testing techniques to assess system vulnerabilities.</li>
                                <li>Understanding Cyber Laws and Regulations: Gain knowledge of cyber laws, regulations, and ethical considerations in cybersecurity.</li>
                                <li>Creating a Cybersecurity Strategy: Learn how to develop a comprehensive cybersecurity strategy for organizations.</li>
                            </>
                        )}
                    </ul>
                    <button className="read-more-btn" onClick={() => setShowMoreMastery(!showMoreMastery)}>
                        {showMoreMastery ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        } else if (activeTab === 'Syllabus') {
            return (
                <div className="syllabus-grid">
                    <div className="syllabus-column">
                        <h3>Week 1: Introduction to Cybersecurity</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(1)}>Overview of Cybersecurity</strong>
                                {expandedWeeks[1] && (
                                    <ul>
                                        <li>Introduction to cybersecurity and its importance in the modern world.</li>
                                        <li>Understanding the basic concepts and terminologies in cybersecurity.</li>
                                        <li>Exploring the various fields and specializations within cybersecurity.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(2)}>Cyber Threats and Vulnerabilities</strong>
                                {expandedWeeks[2] && (
                                    <ul>
                                        <li>Identifying different types of cyber threats and vulnerabilities.</li>
                                        <li>Understanding the methods used by attackers to exploit vulnerabilities.</li>
                                        <li>Analyzing real-world examples of cyber attacks.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Week 2: Network Security</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(3)}>Securing Networks</strong>
                                {expandedWeeks[3] && (
                                    <ul>
                                        <li>Introduction to network security principles and practices.</li>
                                        <li>Implementing firewalls and intrusion detection systems (IDS).</li>
                                        <li>Understanding network encryption and secure communication.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(4)}>Wireless Security</strong>
                                {expandedWeeks[4] && (
                                    <ul>
                                        <li>Securing wireless networks and devices.</li>
                                        <li>Understanding wireless encryption protocols (WPA2, WPA3).</li>
                                        <li>Preventing wireless attacks such as rogue access points and man-in-the-middle attacks.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Week 3: Securing Applications and Systems</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(5)}>Application Security</strong>
                                {expandedWeeks[5] && (
                                    <ul>
                                        <li>Introduction to application security and common vulnerabilities.</li>
                                        <li>Implementing secure coding practices to prevent SQL injection, XSS, and CSRF.</li>
                                        <li>Using security tools to analyze and secure applications.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(6)}>Operating System Security</strong>
                                {expandedWeeks[6] && (
                                    <ul>
                                        <li>Securing operating systems to prevent unauthorized access and exploitation.</li>
                                        <li>Implementing security features such as user permissions, encryption, and system updates.</li>
                                        <li>Understanding and mitigating operating system vulnerabilities.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Week 4: Cybersecurity Strategy and Best Practices</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(7)}>Creating a Cybersecurity Strategy</strong>
                                {expandedWeeks[7] && (
                                    <ul>
                                        <li>Developing a comprehensive cybersecurity strategy for organizations.</li>
                                        <li>Understanding risk management and incident response planning.</li>
                                        <li>Implementing cybersecurity best practices and continuous improvement.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(8)}>Ethical Hacking and Penetration Testing</strong>
                                {expandedWeeks[8] && (
                                    <ul>
                                        <li>Introduction to ethical hacking and its role in cybersecurity.</li>
                                        <li>Performing penetration testing to identify and mitigate vulnerabilities.</li>
                                        <li>Understanding the legal and ethical considerations of ethical hacking.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Instructor') {
            return (
                <div className="instructor-section">
                    <div className="instructor-header">
                        <h2>About Me, <span>Mustafa Khanani</span></h2>
                        <p>I'm a cybersecurity expert with over 15 years of experience in the field. My passion lies in helping individuals and organizations understand the importance of securing their digital assets and staying protected in the ever-evolving landscape of cyber threats.</p>
                    </div>
                    <div className="instructor-profile">
                        <div className="instructor-photo">
                            <img src="/images/instructor-photo.png" alt="Mustafa Khanani" />
                        </div>
                        <div className="instructor-info">
                            <h3>Your guide to mastering cybersecurity</h3>
                            <p>I have worked with top companies to secure their systems and have conducted numerous training sessions to share my knowledge with aspiring cybersecurity professionals.</p>
                            <p>Outside of teaching, I’m committed to staying ahead of the curve in the cybersecurity world, continually researching the latest threats and defenses.</p>
                        </div>
                    </div>
                    <div className="instructor-clients">
                        <h3>Clients</h3>
                        <ul>
                            <li>ACME Corp <span>June 15, 2023</span></li>
                            <li>TechSecure <span>July 20, 2023</span></li>
                            <li>DataSafe <span>August 10, 2023</span></li>
                            <li>ShieldNet <span>September 5, 2023</span></li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Reviews') {
            return (
                <div>
                    {/* Reviews content goes here */}
                </div>
            );
        }
    };

    return (
        <div className="course-page">
            <div className="main-content">
                <div className="course-header">
                    <div className="title-and-rating">
                        <div className="course-title-badge">
                            <h1>Intro to Cybersecurity</h1>
                        </div>
                        <div className="course-rating">
                            <span>4.8</span> <span>(10 reviews)</span>
                        </div>
                    </div>
                    <div className="course-image">
                        <img src="/images/cybersecurity-course-preview.png" alt="Intro to Cybersecurity" />
                    </div>
                    <div className="course-tabs">
                        <button
                            className={`tab ${activeTab === 'Overview' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Overview')}
                        >
                            Overview
                        </button>
                        <button
                            className={`tab ${activeTab === 'Syllabus' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Syllabus')}
                        >
                            Syllabus
                        </button>
                        <button
                            className={`tab ${activeTab === 'Instructor' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Instructor')}
                        >
                            Instructor
                        </button>
                        <button
                            className={`tab ${activeTab === 'Reviews' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Reviews')}
                        >
                            Reviews (100+)
                        </button>
                    </div>
                </div>

                {renderTabContent()}
            </div>

            <div className="course-sidebar">
                <h2>Course Content</h2>
                <div className="course-info">
                    <p><img src="/images/video-icon.png" alt="Video Content" /> 12 hours+ on-demand video content</p>
                    <p><img src="/images/keyboard-icon.png" alt="Keyboard Exercises" /> 14 hands-on-keyboard exercises</p>
                    <p><img src="/images/certificate-icon.png" alt="Certificate" /> Certificate of completion</p>
                    <p><img src="/images/level-icon.png" alt="Level" /> Beginner</p>
                </div>

                <div className="course-price">
                    <p className="original-price">AED 5000</p>
                    <p className="discounted-price"> AED 4000</p>
                </div>
                <button className="buy-now-btn">Buy now</button>
                <button className="add-to-cart-btn" onClick={() => addToCart(product)}>Add to Cart</button> {/* Add to Cart Button */}
                
                <div className="payment-options">
                    <p>Secure payment with:</p>
                    <div className="payment-logos">
                        <img src="/images/paypal-logo.png" alt="PayPal" />
                        <img src="/images/stripe-logo.png" alt="Stripe" />
                        <img src="/images/visa-logo.png" alt="Visa" />
                        <img src="/images/mastercard-logo.png" alt="Mastercard" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroToCybersecurity;
