import React, { useContext, useState, useEffect, useCallback } from 'react';
import './CheckoutPage.css';
import { CartContext } from '../CartContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51Ptt33CkzNEXLPpL7fDjo22p757YnYFUn3HVFdp5Z2e7uwlBX2OppG1MfH4bPh9vjQVr7LXK2oQkKfMYQFJZhLtm00wY0H9VKd');

const CheckoutPage = () => {
    const { cartItems } = useContext(CartContext);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('stripe'); // Set default to Stripe for testing
    const [clientSecret, setClientSecret] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const calculateTotal = useCallback(() => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    }, [cartItems]);

    useEffect(() => {
        const fetchClientSecret = async () => {
            const totalAmount = calculateTotal() * 100; // Convert to the smallest currency unit
            const response = await axios.post('http://localhost:5001/create-payment-intent', { amount: totalAmount });
            setClientSecret(response.data.clientSecret);
        };

        fetchClientSecret();
    }, [calculateTotal]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.error('Stripe.js has not yet loaded or elements not initialized.');
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            console.error('CardElement not found');
            return;
        }

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            }
        });

        if (error) {
            console.log('[error]', error);
        } else if (paymentIntent.status === 'succeeded') {
            console.log('Payment successful!');
        }
    };

    return (
        <Elements stripe={stripePromise}>
            <div className="checkout-page">
                <div className="breadcrumb">
                    <span>Cart</span> &gt; <span>Checkout</span> &gt; <span>Payment</span>
                </div>
                <div className="checkout-content">
                    <div className="payment-options">
                        <h3>Select Payment Method</h3>
                        <div className="payment-methods">
                            <button
                                className={`payment-option ${selectedPaymentMethod === 'stripe' ? 'selected' : ''}`}
                                onClick={() => setSelectedPaymentMethod('stripe')}
                            >
                                Stripe
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="payment-form">
                        <div className="input-group">
                            <label>Card Details</label>
                            <CardElement />
                        </div>
                        <button className="pay-now-btn" type="submit" disabled={!stripe || !clientSecret}>
                            Pay with Stripe
                        </button>
                    </form>
                    <div className="order-summary">
                        <h3>You’ve to pay,</h3>
                        <div className="total-amount">AED {calculateTotal()}</div>
                    </div>
                </div>
            </div>
        </Elements>
    );
};

export default CheckoutPage;
