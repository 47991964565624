import React, { useState, useContext } from 'react';
import './IntroToPythonProgramming.css';
import { CartContext } from '../CartContext'; // Import the CartContext

const IntroToPythonProgramming = () => {
    const [activeTab, setActiveTab] = useState('Overview');
    const [showMoreMastery, setShowMoreMastery] = useState(false);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const { addToCart } = useContext(CartContext); // Get the addToCart function from CartContext

    // Product object with image property
    const product = {
        id: 'intro-python-programming',
        name: 'Intro to Python Programming',
        price: 4000,
        originalPrice: 6000,
        image: '/images/python-icon.png' // Path to the product image
    };

    const toggleWeek = (week) => {
        setExpandedWeeks(prevState => ({
            ...prevState,
            [week]: !prevState[week]
        }));
    };

    const renderTabContent = () => {
        if (activeTab === 'Overview') {
            return (
                <div className="course-description">
                    <h2>Description</h2>
                    <p>Welcome to Intro to Python Programming. This course will equip you with the fundamental skills needed to start your journey in programming. You will learn about Python’s syntax, data types, control structures, and functions. By the end of this course, you’ll be able to write basic Python scripts and understand the core concepts of programming.</p>
                    <h3>What You Will Master</h3>
                    <ul className={`mastery-list ${showMoreMastery ? 'expanded' : ''}`}>
                        <li>Understanding Python Syntax: Learn the basics of Python syntax and structure, which are key to writing clean, efficient code.</li>
                        <li>Data Types and Variables: Grasp the use of various data types and how to manipulate them using Python.</li>
                        <li>Control Structures: Master the use of control structures such as loops and conditional statements in Python programming.</li>
                        <li>Writing Functions: Learn how to write and utilize functions to create reusable code blocks.</li>
                        {showMoreMastery && (
                            <>
                                <li>Working with Lists and Dictionaries: Get hands-on experience with Python’s powerful data structures like lists and dictionaries.</li>
                                <li>File I/O: Understand how to read from and write to files using Python.</li>
                                <li>Error Handling: Learn how to handle exceptions and errors in your code gracefully.</li>
                                <li>Introduction to Object-Oriented Programming: Get a basic understanding of object-oriented programming concepts in Python.</li>
                                <li>Using Python Libraries: Learn how to use some of the most common Python libraries to extend the functionality of your code.</li>
                                <li>Building Simple Projects: Apply your knowledge by building simple Python projects.</li>
                                <li>Problem-Solving Skills: Enhance your problem-solving skills by tackling real-world coding challenges.</li>
                                <li>Developing a Programming Mindset: Learn how to think like a programmer and approach coding challenges methodically.</li>
                            </>
                        )}
                    </ul>
                    <button className="read-more-btn" onClick={() => setShowMoreMastery(!showMoreMastery)}>
                        {showMoreMastery ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        } else if (activeTab === 'Syllabus') {
            return (
                <div className="syllabus-grid">
                    <div className="syllabus-column">
                        <h3>Month 1: Python Basics & Syntax</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(1)}>Week 1: Introduction to Python</strong>
                                {expandedWeeks[1] && (
                                    <ul>
                                        <li>Overview of Python: Understanding Python as a programming language and its applications.</li>
                                        <li>Setting Up Python: Learn how to install and set up Python on your system.</li>
                                        <li>Running Python Scripts: Get introduced to different ways of running Python scripts.</li>
                                        <li>Understanding Python Syntax: Learn about Python’s clean syntax and how to write your first lines of code.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(2)}>Week 2: Data Types and Variables</strong>
                                {expandedWeeks[2] && (
                                    <ul>
                                        <li>Introduction to Data Types: Learn about various data types in Python such as integers, floats, strings, and booleans.</li>
                                        <li>Working with Variables: Understand how to create and manipulate variables in Python.</li>
                                        <li>Basic Arithmetic Operations: Learn how to perform basic arithmetic operations in Python.</li>
                                        <li>Type Conversion: Learn how to convert data from one type to another in Python.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(3)}>Week 3: Control Structures</strong>
                                {expandedWeeks[3] && (
                                    <ul>
                                        <li>Introduction to Control Structures: Learn about control structures such as loops and conditionals in Python.</li>
                                        <li>Using If-Else Statements: Understand how to use if-else statements to control the flow of your program.</li>
                                        <li>Working with Loops: Learn how to use loops like for and while loops to repeat actions in your code.</li>
                                        <li>Control Flow Techniques: Learn techniques to control the flow of your program effectively.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(4)}>Week 4: Functions in Python</strong>
                                {expandedWeeks[4] && (
                                    <ul>
                                        <li>Introduction to Functions: Learn the concept of functions and how to create them in Python.</li>
                                        <li>Using Parameters and Arguments: Understand how to pass information to functions using parameters and arguments.</li>
                                        <li>Return Values: Learn how to return values from functions to be used elsewhere in your code.</li>
                                        <li>Scope and Lifetime of Variables: Understand the scope and lifetime of variables in Python.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 2: Intermediate Python & Data Structures</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(5)}>Week 5: Lists and Dictionaries</strong>
                                {expandedWeeks[5] && (
                                    <ul>
                                        <li>Introduction to Lists: Learn how to create and manipulate lists in Python.</li>
                                        <li>Working with Dictionaries: Understand how to use dictionaries to store key-value pairs.</li>
                                        <li>Common List Operations: Learn common list operations such as slicing, indexing, and appending.</li>
                                        <li>Nesting Data Structures: Learn how to nest data structures for more complex data manipulation.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(6)}>Week 6: File I/O in Python</strong>
                                {expandedWeeks[6] && (
                                    <ul>
                                        <li>Introduction to File Handling: Learn how to open, read, and write files in Python.</li>
                                        <li>Reading from Files: Understand how to read data from files.</li>
                                        <li>Writing to Files: Learn how to write data to files in Python.</li>
                                        <li>Working with CSV Files: Learn how to work with CSV files for data storage and retrieval.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(7)}>Week 7: Error Handling</strong>
                                {expandedWeeks[7] && (
                                    <ul>
                                        <li>Introduction to Error Handling: Understand why and how errors occur in Python.</li>
                                        <li>Using Try-Except Blocks: Learn how to handle errors gracefully using try-except blocks.</li>
                                        <li>Common Exceptions: Get familiar with common Python exceptions and how to handle them.</li>
                                        <li>Raising Exceptions: Learn how to raise your own exceptions in Python.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(8)}>Week 8: Introduction to Object-Oriented Programming (OOP)</strong>
                                {expandedWeeks[8] && (
                                    <ul>
                                        <li>Understanding OOP: Learn the basic principles of object-oriented programming.</li>
                                        <li>Creating Classes and Objects: Understand how to create classes and objects in Python.</li>
                                        <li>Using Methods and Attributes: Learn how to define and use methods and attributes in your classes.</li>
                                        <li>Inheritance and Polymorphism: Get an introduction to more advanced OOP concepts like inheritance and polymorphism.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 3: Advanced Python Techniques</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(9)}>Week 9: Using Python Libraries</strong>
                                {expandedWeeks[9] && (
                                    <ul>
                                        <li>Introduction to Python Libraries: Learn about the rich ecosystem of Python libraries.</li>
                                        <li>Using Numpy for Numerical Computing: Understand how to use Numpy for numerical operations.</li>
                                        <li>Using Pandas for Data Analysis: Learn the basics of using Pandas for data analysis and manipulation.</li>
                                        <li>Exploring Matplotlib: Get introduced to Matplotlib for creating visualizations.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(10)}>Week 10: Working with APIs</strong>
                                {expandedWeeks[10] && (
                                    <ul>
                                        <li>Introduction to APIs: Learn what APIs are and how to interact with them using Python.</li>
                                        <li>Sending HTTP Requests: Understand how to send GET and POST requests using the Requests library.</li>
                                        <li>Parsing JSON Data: Learn how to parse JSON data from API responses.</li>
                                        <li>Building a Simple API Client: Practice building a simple client to interact with an API.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(11)}>Week 11: Building Simple Projects</strong>
                                {expandedWeeks[11] && (
                                    <ul>
                                        <li>Project Planning: Learn how to plan and scope small Python projects.</li>
                                        <li>Building a Simple Calculator: Practice building a calculator using Python functions and control structures.</li>
                                        <li>Creating a To-Do List App: Learn how to create a basic to-do list application using Python’s data structures.</li>
                                        <li>Building a Web Scraper: Understand how to build a web scraper using BeautifulSoup and Requests.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(12)}>Week 12: Final Project</strong>
                                {expandedWeeks[12] && (
                                    <ul>
                                        <li>Project Selection: Choose a project that aligns with your interests and skills.</li>
                                        <li>Project Implementation: Start building your final project, applying all the concepts you’ve learned.</li>
                                        <li>Testing and Debugging: Learn how to test and debug your project to ensure it works as expected.</li>
                                        <li>Final Presentation: Present your project and receive feedback from peers and instructors.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 4: Course Review & Next Steps</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(13)}>Week 13: Reviewing Key Concepts</strong>
                                {expandedWeeks[13] && (
                                    <ul>
                                        <li>Reviewing Python Basics: Go over the basic Python concepts you’ve learned throughout the course.</li>
                                        <li>Advanced Python Techniques: Review advanced topics like OOP, APIs, and libraries.</li>
                                        <li>Revisiting Common Pitfalls: Identify and learn how to avoid common mistakes in Python programming.</li>
                                        <li>Preparing for the Job Market: Tips for preparing for coding interviews and job applications.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(14)}>Week 14: Final Course Review</strong>
                                {expandedWeeks[14] && (
                                    <ul>
                                        <li>Comprehensive Course Review: Review all the topics covered in the course.</li>
                                        <li>Discussing Next Steps: Explore what you can do next to continue your Python learning journey.</li>
                                        <li>Staying Updated: Learn how to stay updated with the latest Python trends and technologies.</li>
                                        <li>Final Reflections: Reflect on what you’ve learned and how it can be applied to your future projects and career.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Instructor') {
            return (
                <div className="instructor-section">
                    <div className="instructor-header">
                        <h2>About Me, <span>Mustafa Khanani</span></h2>
                        <p>I'm a passionate Python Developer & Educator with over a decade of experience in the tech industry. I specialize in helping beginners understand and master Python programming through clear, hands-on instruction.</p>
                    </div>
                    <div className="instructor-profile">
                        <div className="instructor-photo">
                            <img src="/images/instructor-photo.png" alt="Mustafa Khanani" />
                        </div>
                        <div className="instructor-info">
                            <h3>I'm here to guide you through your Python programming journey</h3>
                            <p>With years of experience and a deep understanding of Python, I'm committed to providing you with the skills and knowledge you need to succeed in programming. Whether you're a complete beginner or looking to refresh your skills, this course is designed to help you achieve your goals.</p>
                            <p>When I'm not teaching, I enjoy contributing to open-source projects, exploring new technologies, and participating in the Python community through conferences and meetups.</p>
                        </div>
                    </div>
                    <div className="instructor-clients">
                        <h3>Clients</h3>
                        <ul>
                            <li>Tech Giants <span>June 10, 2023</span></li>
                            <li>Startups.io <span>May 20, 2023</span></li>
                            <li>Pythonistas <span>July 5, 2023</span></li>
                            <li>CodeMasters <span>August 15, 2023</span></li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Reviews') {
            return (
                <div>
                    {/* Reviews content goes here */}
                </div>
            );
        }
    };

    return (
        <div className="course-page">
            <div className="main-content">
                <div className="course-header">
                    <div className="title-and-rating">
                        <div className="course-title-badge">
                            <h1>Intro to Python Programming</h1>
                        </div>
                        <div className="course-rating">
                            <span>4.8</span> <span>(10 reviews)</span>
                        </div>
                    </div>
                    <div className="course-image">
                        <img src="/images/python-course-preview.png" alt="Intro to Python Programming" />
                    </div>
                    <div className="course-tabs">
                        <button
                            className={`tab ${activeTab === 'Overview' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Overview')}
                        >
                            Overview
                        </button>
                        <button
                            className={`tab ${activeTab === 'Syllabus' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Syllabus')}
                        >
                            Syllabus
                        </button>
                        <button
                            className={`tab ${activeTab === 'Instructor' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Instructor')}
                        >
                            Instructor
                        </button>
                        <button
                            className={`tab ${activeTab === 'Reviews' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Reviews')}
                        >
                            Reviews (100+)
                        </button>
                    </div>
                </div>

                {renderTabContent()}
            </div>

            <div className="course-sidebar">
                <h2>Course Content</h2>
                <div className="course-info">
                    <p><img src="/images/video-icon.png" alt="Video Content" /> 15 hours+ on-demand video content</p>
                    <p><img src="/images/keyboard-icon.png" alt="Keyboard Exercises" /> 20 hands-on-keyboard exercises</p>
                    <p><img src="/images/certificate-icon.png" alt="Certificate" /> Certificate of completion</p>
                    <p><img src="/images/level-icon.png" alt="Level" /> Beginner</p>
                </div>

                <div className="course-price">
                    <p className="original-price">AED 6000</p>
                    <p className="discounted-price"> AED 4000</p>
                </div>
                <button className="buy-now-btn">Buy now</button>
                <button className="add-to-cart-btn" onClick={() => addToCart(product)}>Add to Cart</button> {/* Add to Cart Button */}
                
                <div className="payment-options">
                    <p>Secure payment with:</p>
                    <div className="payment-logos">
                        <img src="/images/paypal-logo.png" alt="PayPal" />
                        <img src="/images/stripe-logo.png" alt="Stripe" />
                        <img src="/images/visa-logo.png" alt="Visa" />
                        <img src="/images/mastercard-logo.png" alt="Mastercard" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroToPythonProgramming;
