import React, { useState, useContext } from 'react';
import './SystemDesignInFigma.css';
import { CartContext } from '../CartContext'; // Import the CartContext

const SystemDesignInFigma = () => {
    const [activeTab, setActiveTab] = useState('Overview');
    const [showMoreMastery, setShowMoreMastery] = useState(false);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const { addToCart } = useContext(CartContext); // Get the addToCart function from CartContext

    // Product object with image property
    const product = {
        id: 'figma-system-design',
        name: 'System Design in Figma',
        price: 5000,
        originalPrice: 7000,
        image: '/images/figma-icon.png' // Path to the product image
    };

    const toggleWeek = (week) => {
        setExpandedWeeks(prevState => ({
            ...prevState,
            [week]: !prevState[week]
        }));
    };

    const renderTabContent = () => {
        if (activeTab === 'Overview') {
            return (
                <div className="course-description">
                    <h2>Description</h2>
                    <p>Welcome to System Design in Figma. This course makes you stand out from other UX/UI designers in the market. You will learn how to create user-friendly and efficient interfaces for large systems, advanced prototyping techniques, and collaboration workflows. By the end of this course, you'll be equipped to design at scale and with confidence.</p>
                    <h3>What You Will Master</h3>
                    <ul className={`mastery-list ${showMoreMastery ? 'expanded' : ''}`}>
                        <li>Mastering Figma’s Interface and Tools: Gain proficiency in using Figma’s interface, shortcuts, and tools to create efficient and effective designs.</li>
                        <li>Understanding and Applying Basic Design Principles: Learn and apply key design principles such as typography, color theory, and layout composition to create visually appealing and user-friendly designs.</li>
                        <li>Creating Wireframes and UI Components: Develop the ability to create low-fidelity wireframes and high-fidelity UI components that are reusable and consistent across projects.</li>
                        <li>Building Interactive Prototypes: Master the art of creating interactive prototypes with basic and advanced interactions, using Figma’s animation tools and features like overlays and auto-layout.</li>
                        {showMoreMastery && (
                            <>
                                <li>Designing Complex User Flows and Wireflows: Learn to design comprehensive user flows and wireflows that map out the entire user journey for large-scale projects.</li>
                                <li>Developing Scalable Design Systems: Acquire the skills to create and manage scalable design systems in Figma, ensuring consistency and reusability across different projects.</li>
                                <li>Collaborating and Receiving Feedback: Hone your ability to collaborate with team members in Figma, conduct design reviews, and iterate on designs based on constructive feedback.</li>
                                <li>Preparing for Design Handoff and Documentation: Learn best practices for preparing your designs for handoff to developers, including creating detailed specifications and documentation.</li>
                                <li>Implementing Accessibility Best Practices: Master the principles of accessible design, ensuring your designs are inclusive and meet common accessibility standards.</li>
                                <li>Applying System Design Principles to Real-world Projects: Gain experience in applying system design principles to large-scale, real-world projects, ensuring scalability and efficiency.</li>
                                <li>Completing a Comprehensive Design Project: Build a comprehensive design project that showcases your ability to apply everything learned throughout the course, from initial research to final presentation.</li>
                                <li>Developing a Professional Portfolio: Learn to document your work and build a professional portfolio that effectively showcases your skills and experience as a UX/UI designer.</li>
                            </>
                        )}
                    </ul>
                    <button className="read-more-btn" onClick={() => setShowMoreMastery(!showMoreMastery)}>
                        {showMoreMastery ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        } else if (activeTab === 'Syllabus') {
            return (
                <div className="syllabus-grid">
                    <div className="syllabus-column">
                        <h3>Month 1: Introduction to Figma & Basic Design Concepts</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(1)}>Week 1: Introduction to Figma</strong>
                                {expandedWeeks[1] && (
                                    <ul>
                                        <li>Overview of Figma: Understanding what Figma is and why it's a powerful tool for UX/UI design.</li>
                                        <li>Setting Up Your Workspace: Learn how to set up your Figma account, organize projects, and use workspaces effectively.</li>
                                        <li>Exploring the Figma Interface: A guided tour of the Figma interface, covering essential tools like the toolbar, layers panel, and properties panel.</li>
                                        <li>Using Figma Shortcuts: Mastering keyboard shortcuts and tips for efficient design work in Figma.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(2)}>Week 2: Basic Design Principles</strong>
                                {expandedWeeks[2] && (
                                    <ul>
                                        <li>Design Thinking and User-Centered Design: Introduction to the design thinking process and the importance of keeping the user at the center of design decisions.</li>
                                        <li>Typography Fundamentals: Understanding the basics of typography, including font selection, hierarchy, and readability.</li>
                                        <li>Color Theory: Learn the basics of color theory, including color harmony, contrast, and meaning.</li>
                                        <li>Layout and Composition: Principles of layout design, including balance, alignment, and visual hierarchy.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(3)}>Week 3: Creating Simple Wireframes</strong>
                                {expandedWeeks[3] && (
                                    <ul>
                                        <li>Introduction to Wireframes: Understanding the role of wireframes in the design process and the difference between low-fidelity and high-fidelity wireframes.</li>
                                        <li>Creating Low-Fidelity Wireframes: Learn how to sketch out basic layouts and ideas using Figma’s wireframing tools.</li>
                                        <li>Common Wireframe Components: Introduction to common wireframe components such as headers, footers, buttons, and forms.</li>
                                        <li>Wireframe Best Practices: Tips for creating clear, effective wireframes that communicate your ideas to stakeholders and developers.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(4)}>Week 4: Designing Basic UI Components</strong>
                                {expandedWeeks[4] && (
                                    <ul>
                                        <li>Understanding UI Components: Introduction to the concept of UI components and their importance in creating consistent, reusable designs.</li>
                                        <li>Designing Buttons and Forms: Learn how to design different types of buttons and form elements (text fields, checkboxes, radio buttons) in Figma.</li>
                                        <li>Creating Navigation Bars: Design a responsive navigation bar that works across different screen sizes.</li>
                                        <li>Using Figma’s Component Library: Introduction to Figma’s component library. Learn how to create, organize, and use components across multiple projects.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 2: Intermediate Figma Techniques & Prototyping</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(5)}>Week 5: Working with Grids and Layouts</strong>
                                {expandedWeeks[5] && (
                                    <ul>
                                        <li>Understanding Grid Systems: Learn about the importance of grid systems in creating balanced and consistent designs.</li>
                                        <li>Setting Up Grids in Figma: Practice setting up and customizing grids in Figma.</li>
                                        <li>Designing for Multiple Screen Sizes: Learn how to design for different screen sizes and resolutions using Figma’s responsive design tools.</li>
                                        <li>Using Auto Layout: Introduction to Figma’s auto-layout feature, which helps create responsive designs that adapt to content changes.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(6)}>Week 6: Creating Interactive Prototypes</strong>
                                {expandedWeeks[6] && (
                                    <ul>
                                        <li>Introduction to Prototyping: Understanding the role of prototypes in the design process.</li>
                                        <li>Creating Basic Interactions: Learn how to create simple interactions in Figma, such as button clicks and page transitions.</li>
                                        <li>Using Figma’s Animation Tools: Introduction to Figma’s animation tools, including smart animate.</li>
                                        <li>Testing Prototypes: Learn how to test your prototypes on different devices and gather feedback from users.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(7)}>Week 7: Advanced Prototyping Techniques</strong>
                                {expandedWeeks[7] && (
                                    <ul>
                                        <li>Working with Overlays: Learn how to create overlays (e.g., modals, tooltips) in Figma prototypes.</li>
                                        <li>Creating Scrolling and Fixed Elements: Understand how to create scrollable areas and fixed elements in Figma prototypes.</li>
                                        <li>Designing Complex Interactions: Introduction to more advanced interactions, such as drag-and-drop and hover states.</li>
                                        <li>Building Interactive User Flows: Learn how to create comprehensive user flows that demonstrate the full user journey.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(8)}>Week 8: Designing User Flows and Wireflows</strong>
                                {expandedWeeks[8] && (
                                    <ul>
                                        <li>Understanding User Flows: Introduction to user flows and their importance in designing intuitive systems.</li>
                                        <li>Creating Wireflows: Learn how to combine wireframes with user flows to create wireflows.</li>
                                        <li>Mapping Out the User Journey: Practice mapping out a user journey from start to finish.</li>
                                        <li>Validating User Flows: Learn how to validate your user flows with stakeholders and users.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 3: Advanced Figma Techniques & Collaboration</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(9)}>Week 9: Advanced Component Design</strong>
                                {expandedWeeks[9] && (
                                    <ul>
                                        <li>Creating Design Systems in Figma: Learn how to create a comprehensive design system in Figma, including components, styles, and guidelines.</li>
                                        <li>Using Auto Layout for Complex Components: Advanced techniques for using auto layout in Figma to create responsive, flexible components.</li>
                                        <li>Managing Component Libraries: Learn how to manage and organize component libraries across multiple projects.</li>
                                        <li>Best Practices for Reusability: Tips for designing components that can be easily reused across different projects.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(10)}>Week 10: Collaboration and Feedback Workflows</strong>
                                {expandedWeeks[10] && (
                                    <ul>
                                        <li>Collaborating in Figma: Learn how to collaborate with team members in Figma using features like real-time editing, comments, and version history.</li>
                                        <li>Conducting Design Reviews: Techniques for conducting effective design reviews in Figma.</li>
                                        <li>Incorporating Feedback into Designs: Learn how to incorporate feedback from stakeholders and users into your designs.</li>
                                        <li>Using Figma’s Version Control: Introduction to Figma’s version history and branching features.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(11)}>Week 11: Design Handoff and Documentation</strong>
                                {expandedWeeks[11] && (
                                    <ul>
                                        <li>Preparing Designs for Handoff: Best practices for preparing your designs for handoff to developers.</li>
                                        <li>Using Figma’s Inspect Feature: Introduction to Figma’s inspect feature, which allows developers to view design specifications and export assets directly from Figma.</li>
                                        <li>Documenting Design Decisions: Learn how to document your design decisions and create style guides.</li>
                                        <li>Creating Developer-friendly Designs: Tips for creating designs that are easy for developers to implement.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(12)}>Week 12: Designing for Accessibility</strong>
                                {expandedWeeks[12] && (
                                    <ul>
                                        <li>Understanding Accessibility in Design: Introduction to accessibility principles and why they are important in UX/UI design.</li>
                                        <li>Implementing Accessibility Best Practices: Learn how to implement accessibility best practices in your Figma designs.</li>
                                        <li>Testing for Accessibility: Learn how to test your designs for accessibility using Figma’s built-in tools and external plugins.</li>
                                        <li>Iterating on Accessible Designs: Tips for iterating on your designs to improve accessibility.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 4: System Design & Real-world Applications</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(13)}>Week 13: Introduction to System Design</strong>
                                {expandedWeeks[13] && (
                                    <ul>
                                        <li>Overview of System Design in UX/UI: Understanding the role of system design in creating scalable, efficient systems.</li>
                                        <li>System Design Methodologies: Learn about different system design methodologies.</li>
                                        <li>Role of System Design in Large-scale Projects: Understanding how system design fits into large-scale projects.</li>
                                        <li>Challenges of System Design: Common challenges in system design, such as maintaining consistency and scalability.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(14)}>Week 14: Designing Complex Systems in Figma</strong>
                                {expandedWeeks[14] && (
                                    <ul>
                                        <li>Breaking Down Complex Systems: Learn how to break down complex systems into manageable components.</li>
                                        <li>Creating Scalable Design Systems: Learn how to create design systems that can scale across multiple projects.</li>
                                        <li>Using Figma for Large-scale Systems: Tips for using Figma to design large-scale systems.</li>
                                        <li>Testing and Iterating on System Designs: Learn how to test and iterate on your system designs.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(15)}>Week 15: Case Studies and Real-world Examples</strong>
                                {expandedWeeks[15] && (
                                    <ul>
                                        <li>Analyzing Successful System Design Projects: In-depth analysis of successful system design projects.</li>
                                        <li>Applying Lessons Learned to Your Own Projects: Practice applying the lessons learned from case studies to your own projects.</li>
                                        <li>Learning from Failures: Case studies of system design projects that failed.</li>
                                        <li>Best Practices for Real-world System Design: Tips and best practices for designing systems in real-world contexts.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(16)}>Week 16: Mid-course Project: Designing a Scalable System</strong>
                                {expandedWeeks[16] && (
                                    <ul>
                                        <li>Project Planning and Scoping: Learn how to plan and scope a system design project.</li>
                                        <li>Designing the System: Apply everything you’ve learned so far to design a scalable system in Figma.</li>
                                        <li>Presenting Your Design: Tips for presenting your system design to stakeholders and peers.</li>
                                        <li>Receiving and Incorporating Feedback: Practice receiving feedback on your design and incorporating it into your project.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 5: Final Project & Portfolio Development</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(17)}>Week 17: Final Project Planning</strong>
                                {expandedWeeks[17] && (
                                    <ul>
                                        <li>Defining the Scope and Objectives: Define the scope and objectives of your final project.</li>
                                        <li>Research and Requirements Gathering: Conduct research and gather requirements for your final project.</li>
                                        <li>Creating a Project Timeline: Learn how to create a project timeline and manage your time effectively.</li>
                                        <li>Setting Up Your Figma Workspace: Set up your Figma workspace for your final project.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(18)}>Week 18: Designing Your Final Project</strong>
                                {expandedWeeks[18] && (
                                    <ul>
                                        <li>Starting Your Design: Begin designing your final project in Figma.</li>
                                        <li>Focusing on User Experience: Focus on creating a user-friendly, intuitive design.</li>
                                        <li>Creating a Comprehensive Design System: Create a comprehensive design system for your project.</li>
                                        <li>Iterating on Your Design: Practice iterating on your design based on feedback and testing.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(19)}>Week 19: Iterating and Refining Your Design</strong>
                                {expandedWeeks[19] && (
                                    <ul>
                                        <li>Conducting User Testing: Learn how to conduct user testing and gather feedback on your design.</li>
                                        <li>Incorporating Feedback into Your Design: Practice incorporating feedback from users, peers, and instructors into your design.</li>
                                        <li>Improving Usability and Accessibility: Focus on improving the usability and accessibility of your design.</li>
                                        <li>Finalizing Your Design: Finalize your design and prepare it for presentation.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(20)}>Week 20: Final Project Presentation</strong>
                                {expandedWeeks[20] && (
                                    <ul>
                                        <li>Presenting Your Final Project: Present your final project to the class.</li>
                                        <li>Receiving Feedback from Peers and Instructors: Receive feedback on your final project from peers and instructors.</li>
                                        <li>Reflecting on Your Design Process: Reflect on your design process and what you’ve learned throughout the course.</li>
                                        <li>Celebrating Your Achievements: Celebrate your achievements and the completion of your final project.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(21)}>Week 21: Building Your Portfolio</strong>
                                {expandedWeeks[21] && (
                                    <ul>
                                        <li>Documenting Your Final Project: Learn how to document your final project for your portfolio.</li>
                                        <li>Creating a Design Portfolio: Learn how to create a professional design portfolio that highlights your skills and experience.</li>
                                        <li>Showcasing Your Work Effectively: Tips for showcasing your work effectively in your portfolio.</li>
                                        <li>Preparing for Job Interviews: Tips for preparing for design job interviews.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(22)}>Week 22: Course Review and Next Steps</strong>
                                {expandedWeeks[22] && (
                                    <ul>
                                        <li>Reviewing Key Concepts and Techniques: Review the key concepts and techniques learned throughout the course.</li>
                                        <li>Discussing Next Steps in Your Design Career: Discuss next steps in your design career.</li>
                                        <li>Preparing for the Job Market: Learn how to prepare for the job market as a UX/UI designer.</li>
                                        <li>Staying Up-to-date with Industry Trends: Tips for staying up-to-date with industry trends and continuing your education as a designer.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Instructor') {
            return (
                <div className="instructor-section">
                    <div className="instructor-header">
                        <h2>About Me, <span>Mustafa Khanani</span></h2>
                        <p>I'm a passionate UX/UI Designer & Educator with over 15 years of experience in the tech industry, specializing in creating intuitive and user-centered designs that bring ideas to life.</p>
                    </div>
                    <div className="instructor-profile">
                        <div className="instructor-photo">
                            <img src="/images/instructor-photo.png" alt="Mustafa Khanani" />
                        </div>
                        <div className="instructor-info">
                            <h3>I'm the instructor you need to take your digital presence to the next level</h3>
                            <p>With a collaborative mindset and a dedication to their craft, I work closely with students to ensure they grasp the core concepts of UX/UI design, preparing them to thrive in a competitive industry.</p>
                            <p>Outside of teaching, I’m deeply involved in the design community, attending and speaking at conferences, and continually refining my craft through personal projects.</p>
                        </div>
                    </div>
                    <div className="instructor-clients">
                        <h3>Clients</h3>
                        <ul>
                            <li>Teamwork.inc <span>May 5, 2023</span></li>
                            <li>Django <span>May 5, 2023</span></li>
                            <li>Pipefy.io <span>May 5, 2023</span></li>
                            <li>Rackspace <span>May 5, 2023</span></li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Reviews') {
            return (
                <div>
                    {/* Reviews content goes here */}
                </div>
            );
        }
    };

    return (
        <div className="course-page">
            <div className="main-content">
                <div className="course-header">
                    <div className="title-and-rating">
                        <div className="course-title-badge">
                            <h1>System Design in Figma</h1>
                        </div>
                        <div className="course-rating">
                            <span>4.7</span> <span>(5 reviews)</span>
                        </div>
                    </div>
                    <div className="course-image">
                        <img src="/images/figma-course-preview.png" alt="System Design in Figma" />
                    </div>
                    <div className="course-tabs">
                        <button
                            className={`tab ${activeTab === 'Overview' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Overview')}
                        >
                            Overview
                        </button>
                        <button
                            className={`tab ${activeTab === 'Syllabus' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Syllabus')}
                        >
                            Syllabus
                        </button>
                        <button
                            className={`tab ${activeTab === 'Instructor' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Instructor')}
                        >
                            Instructor
                        </button>
                        <button
                            className={`tab ${activeTab === 'Reviews' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Reviews')}
                        >
                            Reviews (100+)
                        </button>
                    </div>
                </div>

                {renderTabContent()}
            </div>

            <div className="course-sidebar">
                <h2>Course Content</h2>
                <div className="course-info">
                    <p><img src="/images/video-icon.png" alt="Video Content" /> 12 hours+ on-demand video content</p>
                    <p><img src="/images/keyboard-icon.png" alt="Keyboard Exercises" /> 14 hands-on-keyboard exercises</p>
                    <p><img src="/images/certificate-icon.png" alt="Certificate" /> Certificate of completion</p>
                    <p><img src="/images/level-icon.png" alt="Level" /> Intermediate</p>
                </div>

                <div className="course-price">
                    <p className="original-price">AED 7000</p>
                    <p className="discounted-price"> AED 5000</p>
                </div>
                <button className="buy-now-btn">Buy now</button>
                <button className="add-to-cart-btn" onClick={() => addToCart(product)}>Add to Cart</button> {/* Add to Cart Button */}
                
                <div className="payment-options">
                    <p>Secure payment with:</p>
                    <div className="payment-logos">
                        <img src="/images/paypal-logo.png" alt="PayPal" />
                        <img src="/images/stripe-logo.png" alt="Stripe" />
                        <img src="/images/visa-logo.png" alt="Visa" />
                        <img src="/images/mastercard-logo.png" alt="Mastercard" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SystemDesignInFigma;
