import React from 'react';
import './Corporate.css';

const Corporate = () => {
  return (
    <div className="corporate-page">
      {/* Hero Section */}
            <section className="hero-section">
                <h1>Empower Your Workforce with Cutting-Edge IT Training</h1>
                <p>Customized training programs designed to elevate your team's skills and productivity.</p>
                <button className="cta-button-hero">Get Started</button>
            </section>

      {/* Overview of Corporate Training */}
      <section className="overview-section">
        <h2>Overview of Corporate Training</h2>
        <p>
          At Tech101, we understand the importance of upskilling your workforce in today’s fast-paced tech landscape.
          Our corporate training programs are designed to meet the unique needs of SMEs, providing flexible and measurable
          training solutions.
        </p>
        <div className="core-values">
          <div className="value">
            <h3>Tailored Programs</h3>
            <p>Customized to meet your specific business needs.</p>
          </div>
          <div className="value">
            <h3>Flexible Delivery</h3>
            <p>Options for on-site, virtual, or hybrid training sessions.</p>
          </div>
          <div className="value">
            <h3>Measurable Outcomes</h3>
            <p>Track the success and impact of your training initiatives.</p>
          </div>
        </div>
      </section>

      {/* Training Programs */}
      <section className="training-programs-section">
        <h2>Training Programs</h2>
          <div className="training-programs-container">
              <div className="program-category">
                  <h3>Cybersecurity</h3>
                  <p>Learn how to protect your organization from cyber threats.</p>
              </div>
              <div className="program-category">
                  <h3>Software Development</h3>
                  <p>Master the latest software development techniques and tools.</p>
              </div>
              <div className="program-category coming-soon">
                  <h3>Data Analysis</h3>
                  <p>Coming Soon</p>
              </div>
              <div className="program-category coming-soon">
                  <h3>Cloud Computing</h3>
                  <p>Coming Soon</p>
              </div>
              <div className="program-category coming-soon">
                  <h3>AI & Machine Learning</h3>
                  <p>Coming Soon</p>
              </div>
          </div>
      </section>

      {/* Customization Options */}
      <section className="customization-section">
        <h2>Customization Options</h2>
        <div className="customization-option">
          <h3>Program Tailoring</h3>
          <p>Our training programs are customized to meet your specific business needs, ensuring relevance and applicability.</p>
        </div>
        <div className="customization-option">
          <h3>Flexible Delivery</h3>
          <p>Choose from on-site, online, or hybrid training sessions that fit your team’s schedule and preferences.</p>
        </div>
        <div className="customization-option">
          <h3>Consultation Process</h3>
          <p>Our experts will work with you to identify your training needs and develop a program that aligns with your business goals.</p>
        </div>
      </section>

      {/* Success Stories/Case Studies */}
      <section className="success-stories-section">
        <h2>Success Stories</h2>
        <div className="case-study">
          <h3>Bin Ghannam Accounting & Auditing</h3>
          <p>
            Our customized cybersecurity training helped Bin Ghannam Accounting & Auditing improve their security posture,
            resulting in a 50% reduction in security incidents.
          </p>
          <blockquote>
            "The training provided by Tech101 was exceptional. It not only equipped our team with the necessary skills but also
            significantly improved our overall security."
          </blockquote>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-grid">
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">How long are the training sessions?</p>
                            <p className="faq-answer">Our training sessions typically range from a few hours to several days, depending on the program and level of customization.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">Can the training be customized for different departments?</p>
                            <p className="faq-answer">Yes, we tailor our training programs to meet the specific needs of different departments within your organization.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">What is the pricing structure?</p>
                            <p className="faq-answer">Pricing varies based on the program, duration, and level of customization. Contact us for a detailed quote.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer Call to Action */}
            <footer className="footer-cta-section">
                <h2>Ready to Empower Your Team?</h2>
                <button className="cta-button">Request a Consultation</button>

                {/* Bottom Copyright Section */}
                <div className="footer-bottom">
                  <p>© Copyright by Tech101. All rights reserved.</p>
                  <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-of-use">Terms of Use</a></li>
                    <li><a href="/legal">Legal</a></li>
                    <li><a href="/sitemap">Site Map</a></li>
                  </ul>
                </div>
            </footer>
        </div>
    );
};

export default Corporate;
