import React, { useState } from 'react';
import './ProgramsSection.css';
import { Link } from 'react-router-dom';

const ProgramsSection = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [activeFilter, setActiveFilter] = useState('All');

    const programs = [
        { 
            title: 'System Design in Figma', 
            description: 'You will learn how to create user-friendly and efficient interfaces for large systems...', 
            duration: '22 Weeks', 
            level: 'Intermediate', 
            price: 'AED 5000',
            image: '/images/figma-icon.png',
            category: 'Web design & UX/UI',
            link: '/courses/system-design-in-figma'
        },
        { 
            title: 'Basics of UX research', 
            description: 'In this course, you will learn about the basic principles and methods of UX research...', 
            duration: '4 Weeks', 
            level: 'Beginner', 
            price: 'AED 8000',
            image: '/images/ui-ux-icon.png',
            category: 'Web design & UX/UI',
            link: '/courses/ux-research-basics'
        },
        { 
            title: 'Advanced JavaScript', 
            description: 'Master the advanced concepts of JavaScript...', 
            duration: '5 Weeks', 
            level: 'Advanced', 
            price: 'TBA',
            image: '/images/javascript-icon.png',
            category: 'Programming basics',
            link: '/courses/advanced-javascript'
        },
        { 
            title: 'Intro to Cybersecurity', 
            description: 'Understand the basics of cybersecurity and how to protect yourself online...', 
            duration: '6 Weeks', 
            level: 'Beginner', 
            price: 'AED 4000',
            image: '/images/cybersecurity-icon.png',
            category: 'Cybersecurity & data protection',
            link: '/courses/intro-to-cybersecurity'
        },
        { 
            title: 'Intro to Python Programming', 
            description: 'Understand the basics of python programming and how to write scripts...', 
            duration: '8 Weeks', 
            level: 'Beginner', 
            price: 'TBA',
            image: '/images/python-icon.png',
            category: 'Programming basics',
            link: '/courses/intro-python-programming'
        },
        { 
            title: 'Intro to Prompt Engineering', 
            description: 'Understand the proper way to write prompts and attain...', 
            duration: '4 Weeks', 
            level: 'Beginner', 
            price: 'AED 1000',
            image: '/images/prompt-engineering-icon.png',
            category: 'Artificial Intelligence and Machine Learning',
            link: '/courses/prompt-engineering'
        }
    ];

    const programsPerPage = 2;

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        setCurrentPage(1);
    };

    const filteredPrograms = activeFilter === 'All' 
        ? programs 
        : programs.filter(program => program.category === activeFilter);

    const indexOfLastProgram = currentPage * programsPerPage;
    const indexOfFirstProgram = indexOfLastProgram - programsPerPage;
    const currentPrograms = filteredPrograms.slice(indexOfFirstProgram, indexOfLastProgram);
    const totalPages = Math.ceil(filteredPrograms.length / programsPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    return (
        <section id="programs-section" className="programs-section"> {/* Added id="programs-section" */}
            <h2>Courses We Offer</h2>
            <div className="programs-container">
                <div className="programs-filter">
                    <ul>
                        <li 
                            className={activeFilter === 'All' ? 'active' : ''} 
                            onClick={() => handleFilterClick('All')}
                        >
                            All
                        </li>
                        <li 
                            className={activeFilter === 'Programming basics' ? 'active' : ''} 
                            onClick={() => handleFilterClick('Programming basics')}
                        >
                            Programming basics
                        </li>
                        <li 
                            className={activeFilter === 'Web design & UX/UI' ? 'active' : ''} 
                            onClick={() => handleFilterClick('Web design & UX/UI')}
                        >
                            Web design & UX/UI
                        </li>
                        <li 
                            className={activeFilter === 'Cybersecurity & data protection' ? 'active' : ''} 
                            onClick={() => handleFilterClick('Cybersecurity & data protection')}
                        >
                            Cybersecurity & data protection
                        </li>
                        <li 
                            className={activeFilter === 'Artificial Intelligence and Machine Learning' ? 'active' : ''} 
                            onClick={() => handleFilterClick('Artificial Intelligence and Machine Learning')}
                        >
                            AI & ML
                        </li>
                    </ul>
                </div>
                <div className="programs-cards">
                    {currentPrograms.length > 0 ? (
                        currentPrograms.map((program, index) => (
                            <div className="program-card" key={index}>
                                <div className="program-icon">
                                    <img src={program.image} alt={`${program.title} icon`} />
                                </div>
                                <h3>{program.title}</h3>
                                <p>{program.description}</p>
                                <div className="program-details">
                                    <span>{program.duration}</span>
                                    <span>{program.level}</span>
                                </div>
                                <div className="program-price">{program.price}</div>
                                {program.link ? (
                                    <Link to={program.link}>
                                        <button className="view-more-btn">View more</button>
                                    </Link>
                                ) : (
                                    <button className="view-more-btn" disabled>View more</button>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No programs found for this category.</p>
                    )}
                </div>
            </div>
            <div className="pagination-container">
                <div className="pagination">
                    <button onClick={prevPage} className="arrow" disabled={currentPage === 1}>{'<'}</button>
                    {[...Array(totalPages)].map((_, i) => (
                        <button 
                            key={i} 
                            onClick={() => setCurrentPage(i + 1)} 
                            className={currentPage === i + 1 ? 'active' : ''}
                        >
                            {i + 1}
                        </button>
                    ))}
                    <button onClick={nextPage} className="arrow" disabled={currentPage === totalPages}>{'>'}</button>
                </div>
            </div>
        </section>
    );
};

export default ProgramsSection;
