import React from 'react';
import './HeroSection.css';

function HeroSection() {
  const handleScrollToPrograms = () => {
    const programsSection = document.getElementById('programs-section');
    if (programsSection) {
      programsSection.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling
    }
  };

  return (
    <section className="hero">
      <div className="hero-backsplash"></div>
      <div className="hero-content">
        <div className="hero-text">
          <h1 className="hero-title">
            Education <span className="highlight">Beyond</span> Boundaries.
          </h1>
          <p className="hero-subtitle">
            Empower Your Future with Cutting-Edge IT Skills and Training
          </p>
        </div>
        <div className="hero-characters">
          <img src="/images/hero-image2.png" alt="Hero" className="hero-image" />
          <img src="/images/hero-image.png" alt="Hero2" className="hero-image" />
        </div>
      </div>
      
      {/* Floating Arrow */}
      <div className="floating-arrow" onClick={handleScrollToPrograms}>
        <img src="/images/arrow-down.png" alt="Scroll down arrow" />
      </div>
    </section>
  );
}

export default HeroSection;
