import React from 'react';
import './Legal.css';

function Legal() {
  return (
    <div className="legal-page">
      <div className="legal-content">
        <h1>Legal Information</h1>
        <p>Last updated: September 2, 2024</p>

        <h2>1. Legal Disclaimer</h2>
        <p>
          The information provided by Tech101 (“we,” “us” or “our”) on tech101.ae (the “Site”) and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application.
        </p>
        <p>
          Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or our mobile application or reliance on any information provided on the site and our mobile application. Your use of the site and our mobile application and your reliance on any information on the site and our mobile application is solely at your own risk.
        </p>

        <h2>2. External Links Disclaimer</h2>
        <p>
          The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
        </p>
        <p>
          We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
        </p>

        <h2>3. Professional Disclaimer</h2>
        <p>
          The Site cannot and does not contain [medical/legal/fitness/health/other] advice. The [medical/legal/fitness/health/other] information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of [medical/legal/fitness/health/other] advice. 
        </p>
        <p>
          The use or reliance of any information contained on this site or our mobile application is solely at your own risk.
        </p>

        <h2>4. Affiliation Disclaimer</h2>
        <p>
          The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links. Our affiliates include [list of affiliates].
        </p>

        <h2>5. Legal Status</h2>
        <p>
          Tech101 is a registered company under the laws of the United Arab Emirates. Our registration number is [your registration number]. We are located at Sharjah Research Technology & Innovation Park, University City, Sharjah, U.A.E.
        </p>

        <h2>6. Contact Us</h2>
        <p>
          If you have any questions about this Legal Information, please contact us at:
        </p>
        <address>
          Tech101 <br />
          Sharjah Research Technology & Innovation Park <br />
          University City <br />
          Sharjah, U.A.E <br />
          Email: support@tech101.ae
        </address>
      </div>
    </div>
  );
}

export default Legal;
