import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './CartPage.css';
import { CartContext } from '../CartContext';

const CartPage = () => {
    const { cartItems, removeFromCart, clearCart, updateQuantity } = useContext(CartContext);
    const navigate = useNavigate();

    const calculateSubtotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };

    return (
        <div className="cart-page">
            <div className="breadcrumb">
                <span>Cart</span> &gt; <span>Checkout</span> &gt; <span>Payment</span>
            </div>
            <div className="cart-content">
                <div className="cart-items">
                    <h2>Your Cart</h2>
                    {cartItems.length === 0 ? (
                        <p>No items in the cart 🥺</p>
                    ) : (
                        cartItems.map((item, index) => (
                            <div className="cart-item" key={index}>
                                <img src={item.image} alt={item.name} />
                                <div className="item-details">
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                </div>
                                <div className="item-quantity">
                                    <button 
                                        className="quantity-btn" 
                                        onClick={() => updateQuantity(item.id, item.quantity - 1)}
                                        disabled={item.quantity === 1}
                                    >
                                        -
                                    </button>
                                    <span>{item.quantity}</span>
                                    <button 
                                        className="quantity-btn" 
                                        onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                    >
                                        +
                                    </button>
                                </div>
                                <div className="item-price">
                                    <span>{`AED ${(item.price * item.quantity).toFixed(2)}`}</span>
                                </div>
                                <button 
                                    className="remove-btn" 
                                    onClick={() => removeFromCart(item.id)}
                                >
                                    X
                                </button>
                            </div>
                        ))
                    )}
                    {cartItems.length > 0 && (
                        <button className="clear-cart-btn" onClick={clearCart}>
                            Clear cart
                        </button>
                    )}
                </div>
                <div className="cart-summary">
                    <h3>Order Summary</h3>
                    <div className="promo-code">
                        <input type="text" placeholder="Promo code" />
                    </div>
                    <div className="order-details">
                        <p>Subtotal: <span>{`AED ${calculateSubtotal()}`}</span></p>
                        <p>Discount: <span>-AED 0.00</span></p>
                        <p>Total: <span>{`AED ${calculateSubtotal()}`}</span></p>
                    </div>
                    <button 
                        className="checkout-btn" 
                        onClick={() => navigate('/checkout')} // Link to Checkout page
                    >
                        Continue to checkout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CartPage;
