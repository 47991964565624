import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import FeedbackModal from './FeedbackModal'; // Import the FeedbackModal component
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const Footer = () => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const openFeedbackModal = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setIsFeedbackOpen(true);
  };
  const closeFeedbackModal = () => setIsFeedbackOpen(false);

  return (
    <footer>
      {/* Newsletter CTA Section */}
      <section className="newsletter-section">
        <div className="newsletter-container">
          <div className="newsletter-image">
            <img src="/images/footer-image.png" alt="Computer" />
          </div>
          <div className="newsletter-content">
            <h2>Subscribe to our newsletter to get updates to our latest collections</h2>
            <p>Get 20% off on your first order just by subscribing to our newsletter</p>
            <form className="newsletter-form">
              <input type="email" placeholder="Enter your email" required />
              <button type="submit">Subscribe</button>
            </form>
            <p className="privacy-text">
              You will be able to unsubscribe at any time. Read our <a href="/privacy-policy">privacy policy</a> here.
            </p>
          </div>
        </div>
      </section>

      {/* Main Footer Section */}
      <div className="footer-content">
        <div className="footer-column">
          <img src="/images/logo-form.png" alt="Company Logo" className="footer-logo" />
          <p>Shaping Tomorrow's Digital Leaders Today.</p>
          <div className="social-icons">
            <a href="https://www.facebook.com/tech101.ae"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/tech101.ae"><i className="fab fa-instagram"></i></a>
            <a href="https://www.linkedin.com/company/tech101fze"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </div>
        <div className="footer-column">
          <h4>Company</h4>
          <ul>
            <li><HashLink to="/about">About Us</HashLink></li>
            <li><HashLink smooth to="/#testimonials-section">Testimonial</HashLink></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Support</h4>
          <ul>
            <li><HashLink smooth to="/contact#faq-section">Help Center</HashLink></li> {/* Updated Link */}
            <li>
              <button className="feedback-link" onClick={openFeedbackModal}>Feedback</button>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Links</h4>
          <ul>
            <li><HashLink smooth to="/#courses-section">Courses</HashLink></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Contact Us</h4>
          <p><i className="fas fa-phone-alt"></i> (971) 56 606 2539</p>
          <p><i className="fas fa-envelope"></i> support@tech101.ae</p>
        </div>
      </div>

      {/* Bottom Copyright Section */}
      <div className="footer-bottom">
        <p>© Copyright by Tech101. All rights reserved.</p>
        <ul>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/terms-of-use">Terms of Use</a></li>
          <li><a href="/legal">Legal</a></li>
          <li><a href="/sitemap">Site Map</a></li>
        </ul>
      </div>

      {/* Feedback Modal */}
      <FeedbackModal isOpen={isFeedbackOpen} onClose={closeFeedbackModal} />
    </footer>
  );
};

export default Footer;
