import React from 'react';
import './signupForm.css';
import logo from './logo-form.png'; // Add your logo here
import googleIcon from './google-icon.png';
import twitterIcon from './twitter-icon.png';

function SignupForm() {
    return (
        <div className="signup-page">
            <div className="signup-left">
                <img src={logo} alt="Logo" className="logo" /> {/* Logo added */}
                <h1>Create an account</h1>
                <p>Let’s get started with your 30-day free trial.</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" placeholder="Name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="Email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" placeholder="Password" required />
                    </div>
                    <button type="submit" className="signup-submit-btn">Create account</button>
                    <div className="divider">or Sign Up with</div> {/* Divider line simplified */}
                    <div className="social-icons">
                        <img src={googleIcon} alt="Google" className="social-icon" />
                        <img src={twitterIcon} alt="Twitter" className="social-icon" />
                    </div>
                </form>
                <p className="login-link">Already have an account? <a href="/login">Log in</a></p>
            </div>
            <div className="signup-right">
                <div className="signup-right-text">
                    <p>“"Be at Tech101 and master the tech skills that will shape your future. Empower your passion, drive your career forward."”</p>
                    <h4>Mustafa Khanani</h4>
                    <span>Founder, Tech101</span>
                </div>
            </div>
        </div>
    );
}

export default SignupForm;
