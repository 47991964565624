// src/pages/Feedback.js
import React from 'react';

function Feedback() {
  return (
    <div className="feedback">
      <h1>Feedback</h1>
      <p>This is the Feedback page content.</p>
    </div>
  );
}

export default Feedback;
