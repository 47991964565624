import React, { useState, useContext } from 'react';
import './BasicsOfUXResearch.css';
import { CartContext } from '../CartContext'; // Import the CartContext

const BasicsOfUXResearch = () => {
    const [activeTab, setActiveTab] = useState('Overview');
    const [showMoreMastery, setShowMoreMastery] = useState(false);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const { addToCart } = useContext(CartContext); // Get the addToCart function from CartContext

    // Product object with image property
    const product = {
        id: 'ux-research-basics',
        name: 'Basics of UX Research',
        price: 4000,
        originalPrice: 6000,
        image: '/images/ux-research-icon.png' // Path to the product image
    };

    const toggleWeek = (week) => {
        setExpandedWeeks(prevState => ({
            ...prevState,
            [week]: !prevState[week]
        }));
    };

    const renderTabContent = () => {
        if (activeTab === 'Overview') {
            return (
                <div className="course-description">
                    <h2>Description</h2>
                    <p>Welcome to Basics of UX Research. This course provides a comprehensive introduction to UX research, helping you understand user needs and behaviors. You will learn fundamental research methods, data analysis techniques, and how to apply insights to improve user experience. By the end of this course, you'll be able to conduct effective UX research and contribute to better design decisions.</p>
                    <h3>What You Will Master</h3>
                    <ul className={`mastery-list ${showMoreMastery ? 'expanded' : ''}`}>
                        <li>Understanding UX Research Fundamentals: Gain a solid foundation in the key concepts and principles of UX research.</li>
                        <li>Conducting User Interviews: Learn how to plan, conduct, and analyze user interviews to gather valuable insights.</li>
                        <li>Creating User Personas: Develop skills in creating user personas that represent your target audience.</li>
                        <li>Designing and Analyzing Surveys: Master the art of designing effective surveys and analyzing the data to uncover user needs.</li>
                        {showMoreMastery && (
                            <>
                                <li>Usability Testing: Learn how to conduct usability tests and identify areas for improvement in your designs.</li>
                                <li>Analyzing Qualitative and Quantitative Data: Gain expertise in analyzing different types of data to inform your design decisions.</li>
                                <li>Applying UX Research to Design: Understand how to apply research findings to create user-centered designs.</li>
                                <li>Collaborating with Stakeholders: Develop the ability to effectively communicate research insights to stakeholders.</li>
                                <li>Creating UX Reports: Learn how to document and present your research findings in a clear and actionable manner.</li>
                                <li>Conducting Competitive Analysis: Understand how to conduct competitive analysis to identify opportunities and gaps in the market.</li>
                                <li>Building a UX Research Portfolio: Develop a professional portfolio that showcases your UX research skills and projects.</li>
                                <li>Completing a UX Research Project: Apply your knowledge in a comprehensive project that demonstrates your ability to conduct end-to-end UX research.</li>
                            </>
                        )}
                    </ul>
                    <button className="read-more-btn" onClick={() => setShowMoreMastery(!showMoreMastery)}>
                        {showMoreMastery ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        } else if (activeTab === 'Syllabus') {
            return (
                <div className="syllabus-grid">
                    <div className="syllabus-column">
                        <h3>Month 1: Introduction to UX Research</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(1)}>Week 1: Introduction to UX Research</strong>
                                {expandedWeeks[1] && (
                                    <ul>
                                        <li>Overview of UX Research: Understanding the role of UX research in design and product development.</li>
                                        <li>Key Concepts and Principles: Learn the foundational principles of UX research.</li>
                                        <li>Planning Your Research: How to plan effective UX research studies.</li>
                                        <li>Ethical Considerations: Understanding the ethical implications of UX research.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(2)}>Week 2: User Interviews</strong>
                                {expandedWeeks[2] && (
                                    <ul>
                                        <li>Preparing for User Interviews: How to prepare for and plan user interviews.</li>
                                        <li>Conducting User Interviews: Techniques for conducting effective user interviews.</li>
                                        <li>Analyzing Interview Data: Learn how to analyze qualitative data from user interviews.</li>
                                        <li>Translating Insights into Design: Using interview data to inform design decisions.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(3)}>Week 3: Creating User Personas</strong>
                                {expandedWeeks[3] && (
                                    <ul>
                                        <li>Understanding User Personas: Introduction to user personas and their role in design.</li>
                                        <li>Creating Effective Personas: Learn how to create detailed and accurate user personas.</li>
                                        <li>Using Personas in Design: How to apply user personas to guide your design process.</li>
                                        <li>Validating Personas: Techniques for validating and refining your user personas.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(4)}>Week 4: Survey Design and Analysis</strong>
                                {expandedWeeks[4] && (
                                    <ul>
                                        <li>Introduction to Surveys: Understanding the role of surveys in UX research.</li>
                                        <li>Designing Effective Surveys: Learn best practices for survey design.</li>
                                        <li>Analyzing Survey Data: Techniques for analyzing quantitative survey data.</li>
                                        <li>Applying Survey Insights: Using survey data to inform design decisions.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 2: Intermediate UX Research Techniques</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(5)}>Week 5: Usability Testing</strong>
                                {expandedWeeks[5] && (
                                    <ul>
                                        <li>Introduction to Usability Testing: Understanding the importance of usability testing in UX research.</li>
                                        <li>Planning and Conducting Usability Tests: Learn how to plan and conduct usability tests.</li>
                                        <li>Analyzing Usability Test Data: Techniques for analyzing data from usability tests.</li>
                                        <li>Iterating on Design: Using usability test insights to improve your designs.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(6)}>Week 6: Analyzing Qualitative Data</strong>
                                {expandedWeeks[6] && (
                                    <ul>
                                        <li>Understanding Qualitative Data: Introduction to qualitative data analysis techniques.</li>
                                        <li>Analyzing User Feedback: How to analyze and interpret user feedback.</li>
                                        <li>Thematic Analysis: Learn how to conduct thematic analysis of qualitative data.</li>
                                        <li>Reporting Qualitative Insights: Best practices for reporting qualitative data findings.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(7)}>Week 7: Analyzing Quantitative Data</strong>
                                {expandedWeeks[7] && (
                                    <ul>
                                        <li>Introduction to Quantitative Data: Understanding the role of quantitative data in UX research.</li>
                                        <li>Statistical Analysis: Learn basic statistical analysis techniques for UX research.</li>
                                        <li>Data Visualization: How to visualize quantitative data effectively.</li>
                                        <li>Making Data-Driven Decisions: Using quantitative data to inform design decisions.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(8)}>Week 8: Conducting Competitive Analysis</strong>
                                {expandedWeeks[8] && (
                                    <ul>
                                        <li>Introduction to Competitive Analysis: Understanding the role of competitive analysis in UX research.</li>
                                        <li>Identifying Competitors: How to identify and evaluate competitors.</li>
                                        <li>Analyzing Competitor Products: Techniques for analyzing competitor products and experiences.</li>
                                        <li>Applying Competitive Insights: Using competitive analysis to inform your design strategy.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 3: Advanced UX Research Techniques & Collaboration</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(9)}>Week 9: Applying UX Research to Design</strong>
                                {expandedWeeks[9] && (
                                    <ul>
                                        <li>Integrating Research into Design: How to apply research insights to the design process.</li>
                                        <li>Collaborating with Design Teams: Techniques for effective collaboration between researchers and designers.</li>
                                        <li>Designing with Data: Using research data to drive design decisions.</li>
                                        <li>Case Studies: Examples of successful research-driven design projects.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(10)}>Week 10: Collaborating with Stakeholders</strong>
                                {expandedWeeks[10] && (
                                    <ul>
                                        <li>Engaging Stakeholders: Techniques for involving stakeholders in the research process.</li>
                                        <li>Communicating Research Findings: How to effectively communicate research insights to stakeholders.</li>
                                        <li>Building Consensus: Strategies for building consensus around research findings.</li>
                                        <li>Driving Design Decisions: How to use research to influence design decisions within an organization.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(11)}>Week 11: Creating UX Reports</strong>
                                {expandedWeeks[11] && (
                                    <ul>
                                        <li>Documenting Research Findings: Best practices for documenting UX research findings.</li>
                                        <li>Creating Actionable Reports: How to create reports that lead to actionable insights.</li>
                                        <li>Presenting Your Findings: Techniques for presenting research findings to different audiences.</li>
                                        <li>Iterating on Reports: How to refine and iterate on your UX reports.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(12)}>Week 12: Building a UX Research Portfolio</strong>
                                {expandedWeeks[12] && (
                                    <ul>
                                        <li>Introduction to UX Research Portfolios: Why and how to create a UX research portfolio.</li>
                                        <li>Documenting Your Work: How to document your research projects effectively.</li>
                                        <li>Showcasing Your Skills: Techniques for showcasing your UX research skills.</li>
                                        <li>Preparing for Job Interviews: Tips for preparing your portfolio for job interviews.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 4: Final Project & Portfolio Development</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(13)}>Week 13: Planning Your UX Research Project</strong>
                                {expandedWeeks[13] && (
                                    <ul>
                                        <li>Project Planning: How to plan your UX research project from start to finish.</li>
                                        <li>Setting Objectives: Defining the objectives and goals for your research project.</li>
                                        <li>Research Methodology: Choosing the right research methods for your project.</li>
                                        <li>Project Timeline: How to create and manage a project timeline.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(14)}>Week 14: Conducting Your Research</strong>
                                {expandedWeeks[14] && (
                                    <ul>
                                        <li>Executing Your Plan: How to execute your UX research plan effectively.</li>
                                        <li>Data Collection: Techniques for collecting data during your research project.</li>
                                        <li>Data Analysis: How to analyze the data you've collected.</li>
                                        <li>Iterating on Your Findings: Using initial findings to iterate and refine your research.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(15)}>Week 15: Finalizing Your Research</strong>
                                {expandedWeeks[15] && (
                                    <ul>
                                        <li>Finalizing Your Data: How to finalize and prepare your data for presentation.</li>
                                        <li>Creating Your Final Report: Best practices for creating a comprehensive UX research report.</li>
                                        <li>Presenting Your Research: How to present your research findings to an audience.</li>
                                        <li>Receiving Feedback: Techniques for receiving and incorporating feedback into your final project.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(16)}>Week 16: Portfolio Development</strong>
                                {expandedWeeks[16] && (
                                    <ul>
                                        <li>Documenting Your Project: How to document your final UX research project for your portfolio.</li>
                                        <li>Creating a Portfolio Case Study: Techniques for creating a compelling case study for your portfolio.</li>
                                        <li>Showcasing Your Work: How to showcase your final project in your UX research portfolio.</li>
                                        <li>Preparing for Interviews: Final tips for preparing your portfolio and yourself for job interviews.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Instructor') {
            return (
                <div className="instructor-section">
                    <div className="instructor-header">
                        <h2>About Me, <span>Mustafa Khanani</span></h2>
                        <p>I'm a passionate UX Researcher & Educator with over 15 years of experience in the tech industry, specializing in user-centered research that drives meaningful design decisions.</p>
                    </div>
                    <div className="instructor-profile">
                        <div className="instructor-photo">
                            <img src="/images/instructor-photo.png" alt="Mustafa Khanani" />
                        </div>
                        <div className="instructor-info">
                            <h3>I'm here to guide you through the world of UX Research, helping you understand users and create impactful designs</h3>
                            <p>With a deep commitment to research and teaching, I work closely with students to ensure they develop the skills needed to excel in UX research and make a real impact in the industry.</p>
                            <p>Outside of teaching, I am active in the UX community, continually learning, sharing knowledge, and refining my craft.</p>
                        </div>
                    </div>
                    <div className="instructor-clients">
                        <h3>Clients</h3>
                        <ul>
                            <li>Teamwork.inc <span>May 5, 2023</span></li>
                            <li>Django <span>May 5, 2023</span></li>
                            <li>Pipefy.io <span>May 5, 2023</span></li>
                            <li>Rackspace <span>May 5, 2023</span></li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Reviews') {
            return (
                <div>
                    {/* Reviews content goes here */}
                </div>
            );
        }
    };

    return (
        <div className="course-page">
            <div className="main-content">
                <div className="course-header">
                    <div className="title-and-rating">
                        <div className="course-title-badge">
                            <h1>Basics of UX Research</h1>
                        </div>
                        <div className="course-rating">
                            <span>4.7</span> <span>(5 reviews)</span>
                        </div>
                    </div>
                    <div className="course-image">
                        <img src="/images/ux-research-course-preview.png" alt="Basics of UX Research" />
                    </div>
                    <div className="course-tabs">
                        <button
                            className={`tab ${activeTab === 'Overview' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Overview')}
                        >
                            Overview
                        </button>
                        <button
                            className={`tab ${activeTab === 'Syllabus' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Syllabus')}
                        >
                            Syllabus
                        </button>
                        <button
                            className={`tab ${activeTab === 'Instructor' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Instructor')}
                        >
                            Instructor
                        </button>
                        <button
                            className={`tab ${activeTab === 'Reviews' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Reviews')}
                        >
                            Reviews (100+)
                        </button>
                    </div>
                </div>

                {renderTabContent()}
            </div>

            <div className="course-sidebar">
                <h2>Course Content</h2>
                <div className="course-info">
                    <p><img src="/images/video-icon.png" alt="Video Content" /> 10 hours+ on-demand video content</p>
                    <p><img src="/images/keyboard-icon.png" alt="Keyboard Exercises" /> 10 hands-on-keyboard exercises</p>
                    <p><img src="/images/certificate-icon.png" alt="Certificate" /> Certificate of completion</p>
                    <p><img src="/images/level-icon.png" alt="Level" /> Beginner</p>
                </div>

                <div className="course-price">
                    <p className="original-price">AED 6000</p>
                    <p className="discounted-price"> AED 4000</p>
                </div>
                <button className="buy-now-btn">Buy now</button>
                <button className="add-to-cart-btn" onClick={() => addToCart(product)}>Add to Cart</button> {/* Add to Cart Button */}
                
                <div className="payment-options">
                    <p>Secure payment with:</p>
                    <div className="payment-logos">
                        <img src="/images/paypal-logo.png" alt="PayPal" />
                        <img src="/images/stripe-logo.png" alt="Stripe" />
                        <img src="/images/visa-logo.png" alt="Visa" />
                        <img src="/images/mastercard-logo.png" alt="Mastercard" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicsOfUXResearch;
