import React from 'react';
import './contact.css';
import worldMapImage from './world-map-image.png';

function Contact() {
    return (
        <div className="contact-page">
            <div className="contact-header">
                <h1>Contact Us</h1>
                <h2>Get in touch with our team</h2>
                <p>We have the team and know-how to help you scale 10x faster.</p>
            </div>

            <div className="map-container">
                <div className="world-map" style={{ backgroundImage: `url(${worldMapImage})` }}>
                    <div className="location-marker" style={{ top: '60%', left: '85%' }}>
                        <div className="location-info">
                            <p>Sharjah Research Technology & Innovation Park</p>
                            <p>University City</p>
                            <p>Sharjah U.A.E</p>
                        </div>
                    </div>
                    {/* Add more markers as needed */}
                </div>
            </div>

            <div className="contact-cards">
                <div className="contact-card">
                    <h3>Chat to Sales</h3>
                    <p>Speak to our friendly team.</p>
                    <a href="mailto:sales@tech101.com">sales@tech101.com</a>
                </div>
                <div className="contact-card">
                    <h3>Chat to Support</h3>
                    <p>We're here to help.</p>
                    <a href="mailto:support@tech101.com">support@tech101.com</a>
                </div>
                <div className="contact-card">
                    <h3>Visit Us</h3>
                    <p>Visit our office HQ.</p>
                    <a href="https://maps.google.com">View on Google Maps</a>
                </div>
                <div className="contact-card">
                    <h3>Call Us</h3>
                    <p>Mon-Fri from 8am to 5pm.</p>
                    <a href="tel:+97156062539">+971 56 606 2539</a>
                </div>
            </div>

            <div id="faq-section" className="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-grid">
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">What courses does Tech101 offer?</p>
                            <p className="faq-answer">We offer a range of IT courses, including programming, web development, cybersecurity, and more. Our courses are designed to cater to beginners as well as advanced learners.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">How do I sign up for a course?</p>
                            <p className="faq-answer">You can sign up for courses directly on our website. Simply navigate to the "Programs" section, select the course you're interested in, and follow the enrollment instructions.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">Are there any free resources available?</p>
                            <p className="faq-answer">Yes, we offer a variety of free resources, including tutorials, articles, and webinars, to help you get started with your learning journey.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">Can I get a refund if I'm not satisfied?</p>
                            <p className="faq-answer">We offer a satisfaction guarantee. If you're not happy with the course, you can request a refund within the first 14 days of enrollment.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">Is there any support available for students?</p>
                            <p className="faq-answer">Absolutely! We have a dedicated support team available to help you with any questions or issues you may encounter during your studies.</p>
                        </div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-icon">Q</div>
                        <div className="faq-content">
                            <p className="faq-question">Do you offer any certifications?</p>
                            <p className="faq-answer">Yes, upon successful completion of our courses, you will receive a certificate that you can share with your network or add to your resume.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
