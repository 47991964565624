import React from 'react';
import './about.css';
import teamImage from './founder.jpg';

function About() {
    return (
        <div className="about-page">
            <div className="about-content">
                <h1>Transforming Digital Learning for a New Era</h1>
                <p className="subheading">While the world evolves, our mission remains unchanged.</p>
                <p>
                    Tech101 was founded with a clear mission: to be the go-to IT platform for students around the world. Our vision is to make quality education accessible to thousands, not just hundreds, by offering affordable resources and personalized one-on-one assistance. We understand that the journey to mastering technology doesn't end with learning the basics. That’s why we also provide corporate training to keep employees up-to-date with the latest cyber risks, offering both basic and advanced knowledge tailored to their needs.
                </p>
                <p>
                    We may not be the pioneers of digital learning, but we’re here to revolutionize the way learning happens. At Tech101, we're not just offering courses; we're creating pathways for real change in the tech industry. We believe in empowering our learners with the skills they need to make a significant impact, whether they're furthering their careers or starting entirely new ones. Our commitment to mentorship ensures that every individual gets the guidance they need to thrive in the ever-evolving world of technology.
                </p>
                <p className="bold final-line">We’re Tech101, and we’re here to deliver learning experiences that truly work — every single time.</p>
            </div>

            {/* Meet Our Team Section */}
            <div className="team-section">
                <h2>Meet Our Team</h2>
                <p className="team-description">Meet our diverse team of world-class creators, designers, and problem solvers.</p>
                <div className="team-filter">
                    <button className="filter-btn active">View all</button>
                    <button className="filter-btn">Management</button>
                    <button className="filter-btn">Product</button>
                    <button className="filter-btn">Design</button>
                    <button className="filter-btn">Marketing</button>
                    <button className="filter-btn">Sales</button>
                    <button className="filter-btn">Customer Success</button>
                    <button className="filter-btn">Operations</button>
                </div>
                <div className="team-grid">
                    {/* Replace with actual team members */}
                    <div className="team-member">
                        <img src={teamImage} alt="Mustafa Khanani, Founder and CEO" className="team-photo" />
                        <h3 className="team-name">Mustafa Khanani</h3>
                        <p className="team-role">Founder and CEO</p>
                    </div>
                    {/* Add more team members similarly */}
                </div>
            </div>
        </div>
    );
}

export default About;
