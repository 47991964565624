import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import { CartContext } from '../CartContext'; // Adjusted path

function NavBar() {
  const { cartItems } = useContext(CartContext); // Accessing cart items from context
  const [menuOpen, setMenuOpen] = useState(false);

  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0); // Calculate total items

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/" className="brand-logo">
          <img src="/images/logo-main.png" alt="Tech101 Logo" />
        </Link>
      </div>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <ul className={`navbar-links ${menuOpen ? 'active' : ''}`}>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/corporate">Corporate</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
      <div className="nav-actions">
        <Link to="/cart" className="cart-link">
          <img src="/images/shopping-cart-icon.png" alt="Shopping Cart" className="cart-icon" />
          {totalItems > 0 && <span className="cart-indicator">{totalItems}</span>} {/* Display indicator if there are items */}
        </Link>
        <Link to="/login" className="login-button">Log in</Link>
      </div>
    </nav>
  );
}

export default NavBar;
