import React from 'react';
import './SiteMap.css';

function SiteMap() {
  return (
    <div className="sitemap-page">
      <div className="sitemap-content">
        <h1>Site Map</h1>
        <p>This is a comprehensive list of all the main sections and pages available on Tech101:</p>

        <div className="sitemap-section">
          <h2>Main Sections</h2>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/corporate">Corporate</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/feedback">Feedback</a></li>
            <li><a href="/faq">FAQ</a></li>
          </ul>
        </div>

        <div className="sitemap-section">
          <h2>Courses</h2>
          <ul>
            <li><a href="/courses/system-design-in-figma">System Design in Figma</a></li>
            <li><a href="/courses/basics-of-ux-research">Basics of UX Research</a></li>
            <li><a href="/courses/advanced-javascript">Advanced JavaScript</a></li>
            <li><a href="/courses/intro-to-cybersecurity">Intro to Cybersecurity</a></li>
            <li><a href="/courses/intro-to-python-programming">Intro to Python Programming</a></li>
          </ul>
        </div>

        <div className="sitemap-section">
          <h2>User Account</h2>
          <ul>
            <li><a href="/login">Login</a></li>
            <li><a href="/signup">Sign Up</a></li>
            <li><a href="/cart">Cart</a></li>
            <li><a href="/checkout">Checkout</a></li>
          </ul>
        </div>

        <div className="sitemap-section">
          <h2>Legal & Policies</h2>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-of-use">Terms of Use</a></li>
            <li><a href="/legal">Legal Information</a></li>
          </ul>
        </div>

        <div className="sitemap-section">
          <h2>Others</h2>
          <ul>
            <li><a href="/testimonials">Testimonials</a></li>
            <li><a href="/sitemap">Site Map</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SiteMap;
