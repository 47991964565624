import React, { useState, useContext } from 'react';
import './AdvancedJavascript.css';
import { CartContext } from '../CartContext'; // Import the CartContext

const AdvancedJavaScript = () => {
    const [activeTab, setActiveTab] = useState('Overview');
    const [showMoreMastery, setShowMoreMastery] = useState(false);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const { addToCart } = useContext(CartContext); // Get the addToCart function from CartContext

    // Product object with image property
    const product = {
        id: 'advanced-javascript',
        name: 'Advanced JavaScript',
        price: 6000,
        originalPrice: 8000,
        image: '/images/javascript-icon.png' // Path to the product image
    };

    const toggleWeek = (week) => {
        setExpandedWeeks(prevState => ({
            ...prevState,
            [week]: !prevState[week]
        }));
    };

    const renderTabContent = () => {
        if (activeTab === 'Overview') {
            return (
                <div className="course-description">
                    <h2>Description</h2>
                    <p>Dive deep into the core principles of JavaScript, mastering the language's advanced concepts to create more efficient, scalable, and optimized code. This course is designed for those looking to take their JavaScript skills to the next level, ensuring a strong foundation in best practices, design patterns, and modern development techniques.</p>
                    <h3>What You Will Master</h3>
                    <ul className={`mastery-list ${showMoreMastery ? 'expanded' : ''}`}>
                        <li>Advanced Functions and Closures: Master complex function behaviors, including closures, IIFE, and the module pattern.</li>
                        <li>Asynchronous JavaScript: Understand and implement promises, async/await, and manage asynchronous operations effectively.</li>
                        <li>JavaScript Design Patterns: Learn and apply essential design patterns like Singleton, Factory, and Observer in your projects.</li>
                        <li>Memory Management and Optimization: Dive into memory leaks, garbage collection, and how to write memory-efficient JavaScript code.</li>
                        {showMoreMastery && (
                            <>
                                <li>Prototypal Inheritance: Master the JavaScript prototype chain and inheritance patterns.</li>
                                <li>JavaScript Engines: Understand how JavaScript engines work under the hood for optimized performance.</li>
                                <li>Modern JavaScript Features: Explore ES6+ features, including classes, modules, and destructuring.</li>
                                <li>Testing and Debugging: Learn advanced debugging techniques and how to write unit tests using popular frameworks.</li>
                                <li>JavaScript in the Browser: Optimize JavaScript for performance in the browser environment.</li>
                                <li>Applying JavaScript to Real-world Projects: Gain experience by applying advanced JavaScript concepts to complex, real-world projects.</li>
                                <li>Completing a Comprehensive JavaScript Project: Build a comprehensive JavaScript project that showcases your ability to apply everything learned throughout the course.</li>
                                <li>Developing a Professional Portfolio: Learn to document your work and build a professional portfolio that effectively showcases your advanced JavaScript skills.</li>
                            </>
                        )}
                    </ul>
                    <button className="read-more-btn" onClick={() => setShowMoreMastery(!showMoreMastery)}>
                        {showMoreMastery ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        } else if (activeTab === 'Syllabus') {
            return (
                <div className="syllabus-grid">
                    <div className="syllabus-column">
                        <h3>Month 1: Advanced JavaScript Concepts</h3>
                        <ul>
                            <li>
                                <strong onClick={() => toggleWeek(1)}>Week 1: Advanced Functions and Scope</strong>
                                {expandedWeeks[1] && (
                                    <ul>
                                        <li>Closures and Lexical Environment: Deep dive into closures and their practical use cases.</li>
                                        <li>Immediately Invoked Function Expressions (IIFE): Understand IIFE and how they can help encapsulate your code.</li>
                                        <li>Scope and Hoisting: Detailed exploration of scope, hoisting, and best practices.</li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <strong onClick={() => toggleWeek(2)}>Week 2: Asynchronous JavaScript</strong>
                                {expandedWeeks[2] && (
                                    <ul>
                                        <li>Callbacks and Promises: Understanding the evolution of asynchronous programming in JavaScript.</li>
                                        <li>Async/Await: Simplifying asynchronous code with async/await syntax.</li>
                                        <li>Error Handling in Asynchronous Code: Best practices for managing errors in asynchronous JavaScript.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(3)}>Week 3: JavaScript Design Patterns</strong>
                                {expandedWeeks[3] && (
                                    <ul>
                                        <li>Introduction to Design Patterns: Overview of essential design patterns used in JavaScript.</li>
                                        <li>Singleton, Factory, and Observer Patterns: Learn how to implement these key patterns in your projects.</li>
                                        <li>Practical Applications of Design Patterns: Case studies on how design patterns are used in real-world JavaScript applications.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(4)}>Week 4: Memory Management and Optimization</strong>
                                {expandedWeeks[4] && (
                                    <ul>
                                        <li>Understanding JavaScript Memory Management: Learn how memory is managed in JavaScript, including garbage collection.</li>
                                        <li>Identifying and Avoiding Memory Leaks: Techniques to detect and prevent memory leaks in your code.</li>
                                        <li>Performance Optimization: Best practices for writing performant JavaScript code.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 2: Mastering JavaScript Engines & Prototypal Inheritance</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(5)}>Week 5: JavaScript Engines and Performance</strong>
                                {expandedWeeks[5] && (
                                    <ul>
                                        <li>Understanding the JavaScript Engine: Learn how engines like V8 work under the hood.</li>
                                        <li>Optimizing JavaScript Performance: Techniques to write optimized JavaScript that performs well in different environments.</li>
                                        <li>Memory Management in JavaScript Engines: Dive into how engines handle memory management and how you can optimize it.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(6)}>Week 6: Prototypal Inheritance</strong>
                                {expandedWeeks[6] && (
                                    <ul>
                                        <li>Understanding the Prototype Chain: Learn how inheritance works in JavaScript through prototypes.</li>
                                        <li>Creating and Managing Prototypes: How to create and manipulate prototypes for efficient code reuse.</li>
                                        <li>Inheritance Patterns in JavaScript: Best practices for using prototypal inheritance in your projects.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(7)}>Week 7: Modern JavaScript Features (ES6+)</strong>
                                {expandedWeeks[7] && (
                                    <ul>
                                        <li>Classes and Modules: Learn how modern JavaScript has evolved with classes and modules.</li>
                                        <li>Destructuring and Spread/Rest Operators: Understand how to use these powerful features to write cleaner code.</li>
                                        <li>Async/Await in Depth: Advanced usage of async/await with examples.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(8)}>Week 8: Testing and Debugging JavaScript</strong>
                                {expandedWeeks[8] && (
                                    <ul>
                                        <li>Advanced Debugging Techniques: Learn how to debug complex JavaScript code efficiently.</li>
                                        <li>Writing Unit Tests: Introduction to writing unit tests using popular frameworks like Jest and Mocha.</li>
                                        <li>Test-Driven Development: How to apply TDD in your JavaScript projects.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="syllabus-column">
                        <h3>Month 3: JavaScript in the Browser & Real-world Applications</h3>
                        <ul>
                            <li><strong onClick={() => toggleWeek(9)}>Week 9: JavaScript in the Browser</strong>
                                {expandedWeeks[9] && (
                                    <ul>
                                        <li>Understanding the Browser Environment: How JavaScript interacts with the DOM, BOM, and other browser APIs.</li>
                                        <li>Optimizing JavaScript for the Browser: Best practices for writing JavaScript that performs well in the browser.</li>
                                        <li>Managing Browser Events: Advanced techniques for handling and optimizing browser events.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(10)}>Week 10: Applying JavaScript to Real-world Projects</strong>
                                {expandedWeeks[10] && (
                                    <ul>
                                        <li>Building Scalable JavaScript Applications: Learn how to structure and scale JavaScript applications effectively.</li>
                                        <li>Case Studies: Real-world examples of successful JavaScript applications and how they were built.</li>
                                        <li>Best Practices for Large-scale JavaScript Projects: Tips and techniques for managing large JavaScript codebases.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(11)}>Week 11: Final Project Development</strong>
                                {expandedWeeks[11] && (
                                    <ul>
                                        <li>Project Planning and Execution: Learn how to plan and execute a final project using all the skills you've acquired.</li>
                                        <li>Real-world Application Development: Build a comprehensive project that incorporates advanced JavaScript concepts.</li>
                                        <li>Feedback and Iteration: Receive feedback on your project and iterate to improve it.</li>
                                    </ul>
                                )}
                            </li>
                            <li><strong onClick={() => toggleWeek(12)}>Week 12: Portfolio Development and Career Guidance</strong>
                                {expandedWeeks[12] && (
                                    <ul>
                                        <li>Building Your Professional Portfolio: Learn how to create a portfolio that showcases your advanced JavaScript skills.</li>
                                        <li>Preparing for Job Interviews: Tips and techniques for acing technical interviews and whiteboard challenges.</li>
                                        <li>Career Guidance: Explore career paths in JavaScript development and how to advance your career.</li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Instructor') {
            return (
                <div className="instructor-section">
                    <div className="instructor-header">
                        <h2>About Me, <span>Mustafa Khanani</span></h2>
                        <p>I'm a seasoned JavaScript Developer & Educator with over 15 years of experience, specializing in advanced JavaScript concepts, scalable architectures, and performance optimization. My passion lies in teaching and helping others master the intricacies of JavaScript to build efficient, modern applications.</p>
                    </div>
                    <div className="instructor-profile">
                        <div className="instructor-photo">
                            <img src="/images/instructor-photo.png" alt="Mustafa Khanani" />
                        </div>
                        <div className="instructor-info">
                            <h3>Your guide to mastering advanced JavaScript</h3>
                            <p>With years of experience in the industry and a deep understanding of JavaScript, I'm here to help you navigate the complexities of the language and become a proficient JavaScript developer. Let's take your coding skills to the next level together!</p>
                        </div>
                    </div>
                    <div className="instructor-clients">
                        <h3>Clients</h3>
                        <ul>
                            <li>Teamwork.inc <span>May 5, 2023</span></li>
                            <li>Django <span>May 5, 2023</span></li>
                            <li>Pipefy.io <span>May 5, 2023</span></li>
                            <li>Rackspace <span>May 5, 2023</span></li>
                        </ul>
                    </div>
                </div>
            );
        } else if (activeTab === 'Reviews') {
            return (
                <div>
                    {/* Reviews content goes here */}
                </div>
            );
        }
    };

    return (
        <div className="course-page">
            <div className="main-content">
                <div className="course-header">
                    <div className="title-and-rating">
                        <div className="course-title-badge">
                            <h1>Advanced JavaScript</h1>
                        </div>
                        <div className="course-rating">
                            <span>4.8</span> <span>(120+ reviews)</span>
                        </div>
                    </div>
                    <div className="course-image">
                        <img src="/images/javascript-course-preview.png" alt="Advanced JavaScript" />
                    </div>
                    <div className="course-tabs">
                        <button
                            className={`tab ${activeTab === 'Overview' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Overview')}
                        >
                            Overview
                        </button>
                        <button
                            className={`tab ${activeTab === 'Syllabus' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Syllabus')}
                        >
                            Syllabus
                        </button>
                        <button
                            className={`tab ${activeTab === 'Instructor' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Instructor')}
                        >
                            Instructor
                        </button>
                        <button
                            className={`tab ${activeTab === 'Reviews' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Reviews')}
                        >
                            Reviews (100+)
                        </button>
                    </div>
                </div>

                {renderTabContent()}
            </div>

            <div className="course-sidebar">
                <h2>Course Content</h2>
                <div className="course-info">
                    <p><img src="/images/video-icon.png" alt="Video Content" /> 15 hours+ on-demand video content</p>
                    <p><img src="/images/keyboard-icon.png" alt="Keyboard Exercises" /> 20 hands-on-keyboard exercises</p>
                    <p><img src="/images/certificate-icon.png" alt="Certificate" /> Certificate of completion</p>
                    <p><img src="/images/level-icon.png" alt="Level" /> Advanced</p>
                </div>

                <div className="course-price">
                    <p className="original-price">AED 8000</p>
                    <p className="discounted-price"> AED 6000</p>
                </div>
                <button className="buy-now-btn">Buy now</button>
                <button className="add-to-cart-btn" onClick={() => addToCart(product)}>Add to Cart</button> {/* Add to Cart Button */}
                
                <div className="payment-options">
                    <p>Secure payment with:</p>
                    <div className="payment-logos">
                        <img src="/images/paypal-logo.png" alt="PayPal" />
                        <img src="/images/stripe-logo.png" alt="Stripe" />
                        <img src="/images/visa-logo.png" alt="Visa" />
                        <img src="/images/mastercard-logo.png" alt="Mastercard" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedJavaScript;
