import React from 'react';
import './TestimonialsSection.css';

const testimonials = [
  {
    name: 'Raja Ahsan Iqbal',
    title: 'Admin & HR',
    text: 'An exceptional presentation on cybersecurity! It was well-structured, visually engaging, and offered practical, actionable insights. The speaker’s expertise and ability to address questions effectively made the session both informative and engaging. A must-watch for anyone who is not familiar about cybersecurity postures.',
    date: 'August 31, 2024',
    company: 'Bin Ghannam Accounting & Auditing',
    avatar: '/images/avatar1.jpeg'
  },
  {
    name: 'Tanvir Ahmad',
    title: 'Manager',
    text: 'An exceptional presentation on Cyber Security, full of key information provided in a very simple but in a very professional manner and it offered a lot to take away. It was a very good learning experience where every minute was worthy of remaining interacted to not to miss any part of it. Truly, it demonstrated the high level of skills & expertise of the presenter in Cyber Security related affairs.',
    date: 'August 31, 2024',
    company: 'Bin Ghannam Accounting & Auditing',
    avatar: '/images/avatar2.jpeg'
  },
];

const TestimonialsSection = () => {
  return (
    <section id="testimonials-section" className="testimonials-section">
      <div className="testimonials-content"> {/* Wrap content inside a container */}
        <h2 className="testimonials-title">What others are saying about Tech101</h2>
        <div className="testimonials-container">
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-card" key={index}>
              <div className="testimonial-header">
                <img src={testimonial.avatar} alt={testimonial.name} className="avatar" />
                <div className="testimonial-info">
                  <h3 className="testimonial-name">{testimonial.name}</h3>
                  <p className="testimonial-title">{testimonial.title}</p>
                </div>
              </div>
              <p className="testimonial-text">{testimonial.text}</p>
              <div className="testimonial-footer">
                <span className="testimonial-company">{testimonial.company}</span>
                <span className="testimonial-date">{testimonial.date}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
