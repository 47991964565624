import React from 'react';
import './TermsOfUse.css';

function TermsOfUse() {
  return (
    <div className="terms-of-use-page">
      <div className="terms-of-use-content">
        <h1>Terms of Use</h1>
        <p>Last updated: September 2, 2024</p>

        <h2>1. Agreement to Terms</h2>
        <p>
          These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Tech101 (“Company”, “we”, “us”, or “our”), concerning your access to and use of the tech101.ae website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Use.
        </p>

        <h2>2. Intellectual Property Rights</h2>
        <p>
          Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United Arab Emirates, foreign jurisdictions, and international conventions.
        </p>

        <h2>3. User Representations</h2>
        <p>
          By using the Site, you represent and warrant that: (1) you have the legal capacity and you agree to comply with these Terms of Use; (2) you are not under the age of 13; (3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; (4) you will not use the Site for any illegal or unauthorized purpose; and (5) your use of the Site will not violate any applicable law or regulation.
        </p>

        <h2>4. Prohibited Activities</h2>
        <p>
          You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
        </p>
        <p>As a user of the Site, you agree not to:</p>
        <ul>
          <li>Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
          <li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
          <li>Engage in unauthorized framing of or linking to the Site.</li>
          <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
        </ul>

        <h2>5. User-Generated Contributions</h2>
        <p>
          The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions").
        </p>

        <h2>6. Site Management</h2>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
        </p>

        <h2>7. Term and Termination</h2>
        <p>
          These Terms of Use shall remain in full force and effect while you use the Site. Without limiting any other provision of these terms of use, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the Site (including blocking certain IP addresses), to any person for any reason or for no reason, including without limitation for breach of any representation, warranty, or covenant contained in these Terms of Use or of any applicable law or regulation.
        </p>

        <h2>8. Modifications and Interruptions</h2>
        <p>
          We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the United Arab Emirates applicable to agreements made and to be entirely performed within the United Arab Emirates, without regard to its conflict of law principles.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions about these Terms of Use, please contact us at:
        </p>
        <address>
          Tech101 <br />
          Sharjah Research Technology & Innovation Park <br />
          University City <br />
          Sharjah, U.A.E <br />
          Email: support@tech101.ae
        </address>
      </div>
    </div>
  );
}

export default TermsOfUse;
