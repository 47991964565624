import React from 'react';
import './loginForm.css';
import backgroundImage from './background-image.jpg';
import googleIcon from './google-icon.png';
import facebookIcon from './facebook-icon.png';
import twitterIcon from './twitter-icon.png';
import linkedinIcon from './linkedin-icon.png';

function LoginForm() {
    return (
        <div className="login-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="login-modal">
                <div className="login-header">
                    <img src="/images/logo-form.png" alt="Logo" className="logo" />
                    <h2>Welcome to Tech101</h2>
                </div>
                <form>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="Email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" placeholder="Password" required />
                    </div>
                    <div className="form-links">
                        <a href="/forgot-password" className="forgot-password">Forgot your password?</a>
                    </div>
                    <button type="submit" className="login-submit-btn">Log in</button>
                </form>

                <div className="divider">
                    <span>or Login with</span>
                </div>

                <div className="social-login">
                    <button className="social-btn google-btn">
                        <img src={googleIcon} alt="Google" />
                    </button>
                    <button className="social-btn facebook-btn">
                        <img src={facebookIcon} alt="Facebook" />
                    </button>
                    <button className="social-btn twitter-btn">
                        <img src={twitterIcon} alt="Twitter" />
                    </button>
                    <button className="social-btn linkedin-btn">
                        <img src={linkedinIcon} alt="LinkedIn" />
                    </button>
                </div>

                <div className="signup-prompt">
                    Don't have an account? <a href="/signup">Sign Up now</a>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
